import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Vdc, DropDownModel } from 'src/app/models/dropdown.model';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { isNullOrUndefined, isEmpty } from 'src/app/core/utilities/vma-common.util';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'vma-single-select-vdc',
  templateUrl: './single-select-vdc.component.html',
  styleUrls: ['./single-select-vdc.component.scss']
})
export class SingleSelectVdcComponent implements OnInit {
  selectedVdc: any;
  vdc: Vdc[];
  selectedBrand: any;
  selectedModelYear: any;
  selectedSeries: any;
  @Output() vdcEvent = new EventEmitter<any>();
  vdcValueSubscription = new Subscription();

  constructor(
    public sharedService: SharedService,
    private readonly vehicleService: VehicleService,
  ) { }

  ngOnInit(): void {
    this.handleClearButton();
  }

  /**
   * @param  {DropDownModel} setbrandId   * 
   */
  @Input()
  set brandId(brandId: DropDownModel) {
    this.vdc = [];
    this.selectedBrand = brandId;
    this.selectedModelYear = null;
    this.selectedSeries = null;
    this.selectedVdc = null;
  }

  /**
   * @param  {number} setyearNo   *
   */
  @Input()
  set yearNo(yearNo: number) {
    this.vdc = [];
    this.selectedSeries = null;
    this.selectedVdc = null;
    this.selectedModelYear = yearNo;
  }

  /**
   * @param  {DropDownModel} setseriesId   * 
   */
  @Input()
  set seriesId(series: DropDownModel) {
    this.vdc = [];
    this.selectedVdc = null;
    this.selectedSeries = series;   
    this.getVdcList();
  }


  getVdcList() {
    this.sharedService.clearPreviousMessages();
    const distributorCd = "91041";
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries)) {
      const parames = {
        brand: this.selectedBrand.id,
        modelyear: this.selectedModelYear,
        series: this.selectedSeries.id
      };
      this.vehicleService.getVdcList(distributorCd, parames).subscribe((res) => {
        this.vdc = new Array<Vdc>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.vdc = res.data[0].facultyCode.facultyCodeList;   
          this.setSelectedVdcValue();   
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onVdcValueChange(event: any) {
    this.selectedVdc = event.value;
    this.vdcEvent.emit(this.selectedVdc);
  }

  handleClearButton() {
    this.vdcValueSubscription = this.sharedService.myObservable$.subscribe(res => {
      if (res) {
        this.selectedVdc = null;
        this.vdcEvent.emit(this.selectedVdc);
      }
    })
  }

  ngOnDestroy(): void {
    this.vdcValueSubscription.unsubscribe();
  }

  setSelectedVdcValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) 
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.vdcValue)) {
      this.selectedVdc = this.sharedService.vehicleSearchData.vdcValue;
      this.vdcEvent.emit(this.selectedVdc);
    }
  }

}
