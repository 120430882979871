import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Vdc, DropDownModel } from 'src/app/models/dropdown.model';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isNullOrUndefined, isEmpty } from 'src/app/core/utilities/vma-common.util';
import { HttpErrorResponse } from '@angular/common/http';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';

@Component({
  selector: 'vma-multi-select-vdc',
  templateUrl: './multi-select-vdc.component.html',
  styleUrls: ['./multi-select-vdc.component.scss']
})
export class MultiSelectVdcComponent implements OnInit {
  selectedVdcs: any;
  vdc: Vdc[];
  selectedBrand: any;
  selectedModelYear: any;
  selectedSeries: any;
  selectedModelCode: any;

  @Output() vdcEvent = new EventEmitter<any>();
  vdcValueSubscription = new Subscription();

  constructor(
    public sharedService: SharedService,
    private readonly vehicleService: VehicleService
  ) { }

  ngOnInit(): void {
    this.handleClearButton();
  }


  @Input()
  set brandId(brandId: DropDownModel) {
    this.vdc = [];
    this.selectedBrand = brandId;
    this.selectedModelYear = null;
    this.selectedSeries = null;    
    this.selectedModelCode = null;
    this.selectedVdcs = null;
  }

  @Input()
  set yearNo(yearNo: number) {
    this.vdc = [];   
    this.selectedModelYear = yearNo;
    this.selectedSeries = null;
    this.selectedModelCode = null;
    this.selectedVdcs = null;    
  }

  @Input()
  set seriesId(series: DropDownModel) {
    this.vdc = [];
    this.selectedSeries = series;
    this.selectedVdcs = null;   
    this.selectedModelCode = null;
  }

  @Input()
  set modelcodeId(modelcode: any) {
    this.vdc = [];
    this.selectedModelCode = modelcode; 
    this.selectedVdcs = null; 
    this.getVdcList();
  }

  getVdcList() {
    this.sharedService.clearPreviousMessages();
    const distributorCd = "91041";
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries)&& !isNullOrUndefined(this.selectedModelCode) 
      && !isEmpty(this.selectedModelCode)) {
      const parames = {
        brand: this.selectedBrand.id,
        modelyear: this.selectedModelYear,
        series: this.selectedSeries.id,
        modelcode: this.selectedModelCode.map(item => item.code).join(',')
      };
      this.vehicleService.getVdcList(distributorCd, parames).subscribe((res) => {
        this.vdc = new Array<Vdc>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.vdc = res.data[0].facultyCode.facultyCodeList;
          this.setSelectedVdcValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onVdcValueChange(event: any) {
    this.selectedVdcs = event.value;
    this.vdcEvent.emit(this.selectedVdcs);
  }

  handleClearButton() {    
    this.vdcValueSubscription = this.sharedService.myObservable$.subscribe(res => {
      if (res) {
        this.selectedVdcs = null;
        this.vdcEvent.emit(this.selectedVdcs);
      }
    })
  }

  setSelectedVdcValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) 
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.vdcValue)) {
      this.selectedVdcs = this.sharedService.vehicleSearchData.vdcValue;
      this.vdcEvent.emit(this.selectedVdcs);
    }
  }

  ngOnDestroy(): void {
    this.vdcValueSubscription.unsubscribe();
  }

}
