import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropDownModel, Distributor } from 'src/app/models/dropdown.model';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { SeriesApiModel } from 'src/app/core/model/api/series-api.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticData } from '../../static-data';

@Component({
  selector: 'vma-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {

  selectedSeries: any;
  series: Array<DropDownModel>;
  selectedBrand: DropDownModel;
  selectedModelYear: number;
  @Output() seriesEvent = new EventEmitter<any>();

  isMandatoryField: boolean;
  viewMode = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
    this.isMandatoryField = this.sharedService.isMandatoryField;
  }

  @Input()
  set brandId(brandId: DropDownModel) {
    this.series = [];
    this.selectedSeries = null;
    this.selectedBrand = brandId;
    this.selectedModelYear = 0;
  }

  @Input()
  set yearNo(yearNo: number) {
    this.series = [];
    this.selectedSeries = null;
    const distributors = StaticData.getDistributorList();
    this.selectedModelYear = yearNo;
    this.listSeries(distributors[0], this.selectedBrand, this.selectedModelYear);
  }

  listSeries(distributor: Distributor, brandId: DropDownModel, yearNo: number) {
    if (!isNullOrUndefined(distributor) && !isNullOrUndefined(brandId)
      && !isNullOrUndefined(yearNo)) {
      const parames = {
        brandid: brandId.id,
        yearno: yearNo
      };
      this.sharedService.clearPreviousMessages();
      this.pcpsDistributorService.listSeries(distributor.distributorId, parames).subscribe(res => {
        this.series = new Array<DropDownModel>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const series: Array<SeriesApiModel> = res.data[0].productGroup;
          series.forEach(element => {
            const data: DropDownModel = {
              id: element.productGroupId, name: element.productGroupNm
            };
            this.series.push(data);
          });
          this.setSelectedSeriesValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onSeriesChange(event: any) {
    this.selectedSeries = event.value;
    this.seriesEvent.emit(this.selectedSeries);
  }

  setSelectedSeriesValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData)
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.seriesId)) {
      this.selectedSeries = this.sharedService.vehicleSearchData.seriesId;
      this.seriesEvent.emit(this.selectedSeries);
    } else {
      // admin component
      if (!isNullOrUndefined(this.sharedService.seriesName)) {
        const seriesName = this.sharedService.seriesName.toUpperCase();
        const series = this.series.find(item => item.name === seriesName);
        if (!isNullOrUndefined(series)) {
          this.selectedSeries = series;
          this.seriesEvent.emit(this.selectedSeries);
          if(this.sharedService.vehicleSearchData.searchType ==="adminview") {
            this.viewMode = true;
          }
        }        
      }
    }
  }
}
