import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../../rest-api/service/rest.service';
import { Response } from '../../rest-api/model/response';
import * as VMA_Util from '../../utilities/vma-common.util';
import { ListVehicleApiRespModel, AuditVehicleListApiRespModel, FacultyCodeListApiRespModel, AccessoryListApiRespModel } from '../../model/api/list-vehicle.model';
import { GetAuditApiResModel } from '../../model/api/get-audit.model';
import { AuditData } from '../../model/ui/audit-data.model';
import { ApiType } from 'src/app/common/app.constants';
import { ListAuditWaiverApiRespModel } from '../../model/api/listAuditWaiver-api.model';
import { ListWaiverHistoryApiRespModel } from '../../model/api/list-waiver.model';
import { AuditWaiverData } from '../../model/ui/audit-admin.data.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  static readonly ADD_VEHICLE_POST_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly SELECT_VEHICLE_POST_API: string = 'audit/vin';
  static readonly LIST_VEHICLES_GET_API: string = 'audit/vehicles';
  static readonly UPDATE_WORKFLOW_PUT_API: string = 'audit/vehicle/vehAssmntEventId/workflow';
  static readonly FETCH_AUDIT_DATA_GET_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly FETCH_S3_PRESIGNED_GET_URL: string = 'audit/s3url';
  static readonly LIST_FINAL_ASSEMBLY_POINTS_GET_API: string = 'content-origin/finalassemblypoint';
  static readonly LIST_COUNTRY_OF_ORIGINS_GET_API: string = 'content-origin/countryoforigin';
  static readonly UPDATE_AUDIT_DATA_PUT_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly LIST_VEHICLES_MODEL_GET_API: string = 'audit/distributors/distributorcd/vehicles';
  static readonly VDCLIST_GET_API: string = 'audit/distributors/distributorcd/vdc';
  static readonly ACCESSORIESLIST_GET_API: string = 'audit/distributors/distributorcd/accessory';
  static readonly LIST_DRC_DATA_GET_API: string = 'audit/vehicle/drc';
  static readonly LIST_AUDIT_WAIVER_HISTORY_API: string = 'audit/admin/audit/auditId';
  static readonly LIST_AUDIT_WAIVER_API: string = 'audit/admin/audit';
  static readonly UPDATE_AUDIT_WAIVER_DETAILS_API: string = 'audit/admin/audit/auditId';
  static readonly UPDATE_AUDIT_WAIVER_API: string = 'audit/admin/audit/auditId';
  static readonly ADD_AUDIT_WAIVER_POST_API: string = 'audit/admin/audit';

  constructor(private readonly restService: RestService) { }

  public addVehicle(vehAssmntEventId: any): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ADD_VEHICLE_POST_API, { vehAssmntEventId });
    return this.restService._post<any>(url, {});
  }

  public selectAuditVehicle(vin: string): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.SELECT_VEHICLE_POST_API, { vin });
    return this.restService._post<any>(url, {});
  }

  public listVehicle(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_VEHICLES_GET_API, {});
    return this.restService._get<ListVehicleApiRespModel>(url, this.listVehicleRespMap, params);
  }

  private listVehicleRespMap(item: any): ListVehicleApiRespModel {
    return new ListVehicleApiRespModel(item);
  }

  public listDRCData(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_DRC_DATA_GET_API, {});
    return this.restService._get<any>(url, this.listDRCDataRespMap, params);
  }

  private listDRCDataRespMap(item: any): any {
    return item;
  }

  public updateWorkflow(vehAssmntEventId: number, reqBody: object, params:object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_WORKFLOW_PUT_API, { vehAssmntEventId });
    return this.restService._put<any>(url, reqBody, params);
  }

  public getAudit(vehAssmntEventId: number): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.FETCH_AUDIT_DATA_GET_API, { vehAssmntEventId });
    return this.restService._get<GetAuditApiResModel>(url, this.getAuditRespMap);
  }
  private getAuditRespMap(item: any): GetAuditApiResModel {
    return new GetAuditApiResModel(item);
  }

  public getFinalAssemblyPoints(): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_FINAL_ASSEMBLY_POINTS_GET_API, {});
    return this.restService._get<any>(url);
  }

  public getCountryOfOrigins(): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_COUNTRY_OF_ORIGINS_GET_API, {});
    return this.restService._get<any>(url);
  }

  public getS3PresignedUrl(params: object): Observable<any> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.FETCH_S3_PRESIGNED_GET_URL, {});
    return this.restService._get<any>(url, this.getS3PresignedUrlResMap, params);
  }
  private getS3PresignedUrlResMap(item: any): any {
    return item;
  }

  public updateAudit(auditData: AuditData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const vehAssmntEventId = auditData.vehAssmntEventId;
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_AUDIT_DATA_PUT_API, { vehAssmntEventId });
    return this.restService._put<any>(url, auditData, {});
  }

  public getAuditVehicleList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_VEHICLES_MODEL_GET_API, { distributorcd });
    return this.restService._get<AuditVehicleListApiRespModel>(url, this.auditListVehicleRespMap, params);
  }

  private auditListVehicleRespMap(item: any): AuditVehicleListApiRespModel {
    return new AuditVehicleListApiRespModel(item);
  }

  public getVdcList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.VDCLIST_GET_API, { distributorcd });
    return this.restService._get<FacultyCodeListApiRespModel>(url, this.vdcListRespMap, params);
  }

  private vdcListRespMap(item: any): FacultyCodeListApiRespModel {
    return new FacultyCodeListApiRespModel(item);
  }

  public getAccessoriesList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ACCESSORIESLIST_GET_API, { distributorcd });
    return this.restService._get<AccessoryListApiRespModel>(url, this.accessoryListRespMap, params);
  }

  private accessoryListRespMap(item: any): AccessoryListApiRespModel {
    return new AccessoryListApiRespModel(item);
  }
  
  public listAuditWaiver(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_AUDIT_WAIVER_API, {});
    return this.restService._get<ListAuditWaiverApiRespModel>(url, this.listAuditWaiverResMap, params);
  }
  private listAuditWaiverResMap(item: any): ListAuditWaiverApiRespModel {
    return new ListAuditWaiverApiRespModel(item);
  }
  public listWaiverHistory(auditId: number, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_AUDIT_WAIVER_HISTORY_API, {auditId});
    return this.restService._get<ListWaiverHistoryApiRespModel>(url, this.listWaiverHistoryRespMap, params);
  }

  private listWaiverHistoryRespMap(item: any):ListWaiverHistoryApiRespModel {
    return new ListWaiverHistoryApiRespModel(item);
  }

  public updateAuditWaiverDetails(auditData: AuditWaiverData, auditId: number): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_AUDIT_WAIVER_DETAILS_API, { auditId });
    return this.restService._put<any>(url, auditData,{},this.updateWaiverRespMap);
  }

  private updateWaiverRespMap(item: any):any {
    return item;
  }
  
  // public  updateAuditWaiver(auditId: number, params: object): Observable<Response<Object>> {
  //   this.restService.setApiBaseEndPoint(ApiType.VMA);
  //   const url = VMA_Util.replaceKeyValueWithString(VehicleService. UPDATE_AUDIT_WAIVER_API, {auditId});
  //   return this.restService._delete<any>(url, params, this.updateAuditWaiverRespMap);
  // }

  // private updateAuditWaiverRespMap(item: any):any{
  //   return item;
  // }

  public addAuditWaiver(auditData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VehicleService.ADD_AUDIT_WAIVER_POST_API;
    return this.restService._post<any>(url, auditData, {}, this.postAuditWaiverRespMap);
  }
 
  private postAuditWaiverRespMap(item: any):any{
    return item;
  }

}
