import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { Distributor, DropDownModel } from 'src/app/models/dropdown.model'
import { StaticData } from '../../static-data';
import { YearApiModel } from 'src/app/core/model/api/year-api.model';

@Component({
  selector: 'vma-modelyear',
  templateUrl: './modelyear.component.html',
  styleUrls: ['./modelyear.component.scss']
})
export class ModelyearComponent implements OnInit {

  selectedModelYear: any;
  modelYears: Array<number>;
  brandIdParams: DropDownModel;
  @Output() yearEvent = new EventEmitter<any>();
  viewMode = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
  }

  @Input()
  set brandId(brandId: DropDownModel) {
    this.modelYears = [];
    this.selectedModelYear = null;
    this.brandIdParams = brandId;
    const distributors = StaticData.getDistributorList();
    this.listModelYears(distributors[0], this.brandIdParams);
  }

  listModelYears(distributor: Distributor, brandId: DropDownModel) {
    if (!isNullOrUndefined(distributor) && !isNullOrUndefined(brandId)) {
      const distributorId: number = distributor.distributorId;
      this.sharedService.clearPreviousMessages();
      const parames = { brandid: brandId.id };
      this.pcpsDistributorService.listYears(distributorId, parames).subscribe((res) => {
        this.modelYears = new Array<number>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const yearsResp: Array<YearApiModel> = res.data[0].years;
          yearsResp.forEach(element => {
            this.modelYears.push(element.modelyearNo);
          });
          this.setSelectedYearNoValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onModelYearChange(event: any) {
    this.selectedModelYear = event.value;
    this.yearEvent.emit(this.selectedModelYear);
  }

  setSelectedYearNoValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData)
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.yearNo)) {
      this.selectedModelYear = this.sharedService.vehicleSearchData.yearNo;
      this.yearEvent.emit(this.selectedModelYear);
      if(this.sharedService.vehicleSearchData.searchType ==="adminview") {
        this.viewMode = true;
      }
    }
  }
}
