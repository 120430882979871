import { Component } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { AddAuditData, AuditAdminListDetails, AuditScopeData, AuditWaiverData } from 'src/app/core/model/ui/audit-admin.data.model';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { HttpErrorResponse } from '@angular/common/http';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { AuditWaiverList } from 'src/app/core/model/ui/audit-vehicle.data.model';

@Component({
  selector: 'vma-drcapproval',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent {
  active: boolean = true;
  displayAddWaiveListPanel: boolean;
  tempGlobalComment = 'comment';
  displayConfirmPopup: boolean;
  fullAuditWaived: boolean = false;
  secondaryAuditWaived: boolean = false;
  modifiedWaived: boolean = false;
  filtermodelYear: string;
  filterseries: string;
  filtermodelCode: string;
  filterauditExceptionInfo: string;
  filterlastUpdatedInfo: string;
  filterstatus: string;
  filterDateAndTime: string;
  filterActionTaken: string;
  filterNotes: string;
  filterUserName: string;
  offset = 0;
  limit = 100;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  auditWaiverData: any = {};
  waivedScopeItems: any[] = [];
  inScopeItems: any[] = [];
  gridDataList: AuditAdminListDetails[] = [];
  cols: any[] = [];
  activeTab: any;
  auditWaiverList = [];
  waiverHistoryList = [];
  waivedScopeList = [];
  auditHistoryList = [];
  deleteConfirmationPopup = false;
  distributorIdParams: any;
  yearNoParams = null;
  seriesIdParams = null;
  modelCodeIdParams: any = {};
  displayPicklist = false;
  waiveAction = false;
  filterAction = false;
  clickEventsubscription: Subscription;
  adminData = [{ modelYear: "2021", series: 'Avalon', modelCode: "2532", auditExceptionInfo: 'Audit Fully Waived', lastUpdatedInfo: 'KLM 2022-01-01 12:00 AM CST', status: 'Active' },
  { modelYear: "2020", series: '4Runner', modelCode: "2222", auditExceptionInfo: 'Audit Scope Modified', lastUpdatedInfo: 'FQA 2023-01-05 11:00 PM CST', status: 'Inactive' },
  { modelYear: "2023", series: 'Avalon Hybrid', modelCode: "4164", auditExceptionInfo: 'Audit Fully Waived', lastUpdatedInfo: 'MTM 2023-02-05 06:00 PM CST', status: 'Active' },
  { modelYear: "2021", series: 'Camry', modelCode: "8642", auditExceptionInfo: 'Audit Waived for secondary', lastUpdatedInfo: 'FQA 2023-01-05 11:00 PM CST', status: 'Inactive' },
  { modelYear: "2020", series: 'Corolla', modelCode: "1169", auditExceptionInfo: 'Audit Waived for secondary', lastUpdatedInfo: 'ABC 2022-12-01 04:00 AM CST', status: 'Active' },
  { modelYear: "2022", series: 'Prius', modelCode: "4516", auditExceptionInfo: 'Audit Fully Waived', lastUpdatedInfo: 'ABC 2022-12-01 04:00 AM CST', status: 'Active' },
  { modelYear: "2022", series: '4Runner', modelCode: "4444", auditExceptionInfo: 'Audit Fully Waived', lastUpdatedInfo: 'MTM 2023-02-05 06:00 PM CST', status: 'Inactive' },
  { modelYear: "2023", series: 'Tacoma', modelCode: "7642", auditExceptionInfo: 'Audit Scope Modified', lastUpdatedInfo: 'KLM 2022-01-01 12:00 AM CST', status: 'Inactive' }];

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly pcpsDistributorService: PcpsDistributorService,
    private readonly router: Router,
    private sharedService: SharedService,
    private primengConfig: PrimeNGConfig,
    private readonly exportExcelService: ExportExcelService,
    private readonly messageService: MessageService
  ) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.addWaiveList();
    });
  }

  ngOnInit() {
    this.sharedService.isMandatoryField = false;
    this.setInScopeItems();
    this.primengConfig.ripple = true;
    this.getSearchDetails();
    this.cols = [
      { field: "dateAndTime", header: "dateAndTime" }, { field: "actionTaken", header: "actionTaken" }, { field: "notes", header: "notes" }, { field: "userName", header: "userName" }];

    this.gridDataList = [
      { dateAndTime: "04-07-2019 8:00PM", actionTaken: "Waiver Turned On", notes: "To release QCS vehicle", userName: 'Brian' },
      { dateAndTime: "05-03-2021 11:00AM", actionTaken: "Waiver Turned Off", notes: "All major pipeline vehicles", userName: 'Brian' },
      { dateAndTime: "06-12-2022 11:00AM", actionTaken: "Waiver Turned Off", notes: "To release QCS vehicle", userName: 'Brian' },
      { dateAndTime: "06-12-2022 11:00AM", actionTaken: "Waiver Turned Off", notes: "To release QCS vehicle", userName: 'Brian' },
      { dateAndTime: "07-11-2022 12:00PM", actionTaken: "Waiver Turned On", notes: "To release QCS vehicle", userName: 'Brian' },
    ];
    this.auditWaiverData.comment = '';
    this.auditWaiverData.active = true;
    this.sharedService.seriesName = null;
    this.sharedService.vehicleSearchData = null;
  }

  setInScopeItems() {
    this.inScopeItems = [
      { scopeType: 'MONRONEY_LABEL', scopeTypeCd: 'Monroney Label' },
      { scopeType: 'AALA_LABEL', scopeTypeCd: 'AALA Label' },
      { scopeType: 'AWR_LABEL', scopeTypeCd: 'AWR Label' },
      { scopeType: 'EMISSION_LABEL', scopeTypeCd: 'Emission Label' },
      { scopeType: 'CR_LABEL', scopeTypeCd: 'CR Label' },
      { scopeType: 'ADDITIONAL_EVID', scopeTypeCd: 'Additional Evidences' },
      { scopeType: 'PTAG_1', scopeTypeCd: 'Production Tag 1' },
      { scopeType: 'PTAG_2', scopeTypeCd: 'Production Tag 2' }];
  }

  generateadminDataExcel() {
    this.exportExcelService.generateadminDataExcel(this.auditWaiverList);
  }

  addWaiveList() {
    this.sharedService.isMandatoryField = true;
    this.auditWaiverData = {};
    this.auditWaiverData.comment = '';
    this.auditWaiverData.active = true;
    this.fullAuditWaived = false;
    this.secondaryAuditWaived = false;
    this.modifiedWaived = false;
    this.waivedScopeItems = [];
    this.displayPicklist = false;
    this.activeTab = false;
    this.setInScopeItems();
    this.auditHistoryList = [];
    this.displayAddWaiveListPanel = true;
    this.sharedService.isAdminView = false;
    this.resetSearchData();
    this.addSearchData();
  }

  addSearchData() {
    this.sharedService.vehicleSearchData = {};
    this.sharedService.vehicleSearchData.searchType = "searchview";
    if (!isNullOrUndefined(this.yearNoParams)) {
      this.sharedService.vehicleSearchData.yearNo = this.yearNoParams;
      this.auditWaiverData.modelYear = this.yearNoParams;
    }
    if (!isNullOrUndefined(this.seriesIdParams)) {
      this.sharedService.seriesName = this.seriesIdParams;
      this.auditWaiverData.series = this.seriesIdParams;
    }
    if (!isNullOrUndefined(this.modelCodeIdParams)) {
      this.sharedService.vehicleSearchData.modelCodeId = this.modelCodeIdParams;
      this.auditWaiverData.modelCodeId = this.modelCodeIdParams;
    }
  }

  waiveFullAudit() {
    this.displayConfirmPopup = true;
    this.waiveAction = true;
    this.auditWaiverData.ActionDesc = 'Waive Full Audit';
  }

  waiveSecondaryAudit() {
    this.displayConfirmPopup = true;
    this.waiveAction = false;
    this.auditWaiverData.ActionDesc = 'Waive Secondary Audit';
  }

  confirmWaiveFullAudit() {
    this.fullAuditWaived = true;
    this.secondaryAuditWaived = false;
    this.modifiedWaived = false;
    this.activeTab = false;
    this.displayConfirmPopup = false;
    this.displayPicklist = false;
    this.waivedScopeItems = [];
    this.setInScopeItems();
    this.auditWaiverData.modifyAction = 'Waive_Full_Audit';
  }

  confirmWaiveSecondaryAudit() {
    this.secondaryAuditWaived = true;
    this.fullAuditWaived = false;
    this.modifiedWaived = false;
    this.activeTab = false;
    this.displayConfirmPopup = false;
    this.displayPicklist = false;
    this.waivedScopeItems = [];
    this.setInScopeItems();
    this.auditWaiverData.modifyAction = 'Waive_Secondary_Audit';
  }

  modifyAuditScope() {
    this.fullAuditWaived = false;
    this.secondaryAuditWaived = false;
    this.modifiedWaived = true;
    this.activeTab = true;
    this.displayPicklist = true;
    this.auditWaiverData.modifyAction = 'Audit_Scope_Modified';
  }

  cancelAudit() {
    this.displayConfirmPopup = false;
  }

  onFiltermodelYear(table1: Table) {
    table1.filter(this.filtermodelYear, 'modelYear', 'contains');
    this.showFilter();
  }

  onClearmodelYear(table1: Table) {
    if (this.filtermodelYear === '') {
      table1.filter(this.filtermodelYear, 'modelYear', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterseries(table1: Table) {
    table1.filter(this.filterseries, 'productGroupId', 'contains');
    this.showFilter();
  }

  onClearseries(table1: Table) {
    if (this.filterseries === '') {
      table1.filter(this.filterseries, 'productGroupId', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFiltermodelCode(table1: Table) {
    table1.filter(this.filtermodelCode, 'modelCd', 'contains');
    this.showFilter();
  }

  onClearmodelCode(table1: Table) {
    if (this.filtermodelCode === '') {
      table1.filter(this.filtermodelCode, 'modelCd', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterauditExceptionInfo(table1: Table) {
    table1.filter(this.filterauditExceptionInfo, 'auditWaiverExcp', 'contains');
    this.showFilter();
  }

  onClearauditExceptionInfo(table1: Table) {
    if (this.filterauditExceptionInfo === '') {
      table1.filter(this.filterauditExceptionInfo, 'auditWaiverExcp', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterlastUpdatedInfo(table1: Table) {
    table1.filter(this.filterlastUpdatedInfo, 'createdts', 'contains');
    this.showFilter();
  }

  onClearlastUpdatedInfo(table1: Table) {
    if (this.filterlastUpdatedInfo === '') {
      table1.filter(this.filterlastUpdatedInfo, 'createdts', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterstatus(table1: Table) {
    table1.filter(this.filterstatus, 'activeInd', 'contains');
    this.showFilter();
  }

  onClearstatus(table1: Table) {
    if (this.filterstatus === '') {
      table1.filter(this.filterstatus, 'activeInd', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterDateAndTime(table2: Table) {
    table2.filter(this.filterDateAndTime, 'updatedTs', 'contains');
  }

  onClearDateAndTime(table2: Table) {
    if (this.filterDateAndTime === '') {
      table2.filter(this.filterDateAndTime, 'updatedTs', 'contains');
    }
  }

  onFilterActionTaken(table2: Table) {
    table2.filter(this.filterActionTaken, 'actionDesc', 'contains');
  }

  onClearActionTaken(table2: Table) {
    if (this.filterActionTaken === '') {
      table2.filter(this.filterActionTaken, 'actionDesc', 'contains');
    }
  }

  onFilterNotes(table2: Table) {
    table2.filter(this.filterNotes, 'comment', 'contains');
  }

  onClearNotes(table2: Table) {
    if (this.filterNotes === '') {
      table2.filter(this.filterNotes, 'comment', 'contains');
    }
  }

  onFilterUserName(table2: Table) {
    table2.filter(this.filterUserName, 'actorNm', 'contains');
  }

  onClearUserName(table2: Table) {
    if (this.filterUserName === '') {
      table2.filter(this.filterUserName, 'actorNm', 'contains');
    }
  }

  onPaginator(event: any) {
    this.offset = event.first;
    this.limit = event.rows;
  }

  searchListData(value: any) {
    this.resetSearchData();
    this.yearNoParams = null;
    this.seriesIdParams = null;
    this.modelCodeIdParams = null;
    if (!isNullOrUndefined(value) && value.type === 'ADMINVIEW') {
      this.setSearchData(value);
    } else if (!isNullOrUndefined(value) && value.type === 'RESETVIEW') {
      this.getSearchDetails();
    } else if (!isNullOrUndefined(value) && value.type === 'SIDEBARVIEW') {
      this.setDefaultSearchData(value);
    }
  }

  setDefaultSearchData(value) {
    this.yearNoParams = value.yearNo;
    if (!isNullOrUndefined(value.seriesId)) {
      this.seriesIdParams = value.seriesId.name;
    } else {
      this.seriesIdParams = null;
    }
    if (!isNullOrUndefined(value.modelCodeId)) {
      this.modelCodeIdParams = value.modelCodeId;
    } else {
      this.modelCodeIdParams = null;
    }
  }

  setSearchData(searchData: any) {
    if (!isNullOrUndefined(searchData.yearNo)) {
      this.yearNoParams = searchData.yearNo;
      if (!isNullOrUndefined(searchData.seriesId)) {
        this.seriesIdParams = searchData.seriesId.name;
      }
      if (!isNullOrUndefined(searchData.modelCodeId)) {
        this.modelCodeIdParams = searchData.modelCodeId;
      }
      this.getSearchDetails();
    } else {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please enter the mandatory fields' });
    }
  }

  getSearchDetails() {
    this.sharedService.isAdminView = true;
    this.sharedService.clearPreviousMessages();
    const params: any = {};
    if (!isNullOrUndefined(this.yearNoParams)) {
      params.modelyear = this.yearNoParams;
    }
    if (!isNullOrUndefined(this.seriesIdParams)) {
      params.series = this.seriesIdParams;
    }
    if (!isNullOrUndefined(this.modelCodeIdParams)
      && !isNullOrUndefined(this.modelCodeIdParams.name)) {
      params.modelcd = this.modelCodeIdParams.name;
    }
    params.offset = 0;
    params.limit = this.limit;
    this.getAuditWaiverDetails(params);
  }

  getAuditWaiverDetails(params) {
    this.auditWaiverList = [];
    this.vehicleService.listAuditWaiver(params).subscribe((res: any) => {      
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditWaiverList = res.data[0].auditWaiverList;
        this.deleteConfirmationPopup = false;
        this.auditWaiverData = {};
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  deleteAuditWaiverDetails(adminData) {
    this.auditWaiverData = {};
    this.auditWaiverData.auditWaiverId = adminData.auditWaiverId;
    this.auditWaiverData.ActionDesc = adminData.auditWaiverExcpTypeCode;
    this.auditWaiverData.modifyAction = adminData.auditWaiverExcpTypeCode;
    this.auditWaiverData.comment = '';
    this.deleteConfirmationPopup = true;
  }

  saveAction() {
    this.sharedService.clearPreviousMessages();
    if (!isNullOrUndefined(this.auditWaiverData.auditWaiverId)) {
      this.updateAuditData();
    } else {
      this.addAuditWaiverDetails();
    }
  }

  cancelAction() {
    this.displayAddWaiveListPanel = false;
  }

  updateAuditData() {
    const auditData = this.setUpdateAuditData();
    if (isNullOrUndefined(auditData.auditWaiverExcp) || isEmpty(auditData.auditWaiverExcp)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Avaliable Options' });
    } else if (auditData.auditWaiverExcp === "Audit_Scope_Modified" && this.waivedScopeItems.length == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Scope Items' });
    } else if (isNullOrUndefined(auditData.historyNotes) || isEmpty(auditData.historyNotes)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Comment should not be empty' });
    } else {
      this.updateAuditWaiverDetails(auditData);
    }
  }

  updateAuditWaiverDetails(auditData) {
    const auditId = this.auditWaiverData.auditWaiverId;
    console.log('auditData', auditData);
    if (!isNullOrUndefined(auditId)) {
      this.vehicleService.updateAuditWaiverDetails(auditData, auditId).subscribe((res: any) => {
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.displayAddWaiveListPanel = false;
          this.getSearchDetails();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error.error.errorDesc });
        } else {
          console.log("error", error);
        }
      })
    } else {
      console.log("AuditId not defined");
    }
  }

  setUpdateAuditData() {
    const scopeArr: Array<AuditScopeData> = [];
    for (let i = 0; i < this.waivedScopeItems.length; i++) {
      if (!isNullOrUndefined(this.waivedScopeItems[i].scopeTypeCd)) {
        const auditScopeModel = new AuditScopeData();
        auditScopeModel.scopeType = this.waivedScopeItems[i].scopeType;
        auditScopeModel.scopeTypeDesc = this.waivedScopeItems[i].scopeTypeCd;
        scopeArr.push(auditScopeModel);
      }
    }
    const auditDataModel = new AuditWaiverData();
    auditDataModel.auditWaiverExcp = this.auditWaiverData.modifyAction;
    auditDataModel.historyNotes = this.auditWaiverData.comment;
    auditDataModel.historyAction = this.setHistoryAction();
    auditDataModel.auditActiveInd = this.auditWaiverData.active ? 'Y' : 'N';
    auditDataModel.auditWaiverScope = scopeArr;
    return auditDataModel;
  }

  getWaiverHistoryDetails() {
    const params: any = {};
    params.offset = 0;
    params.limit = this.limit;
    const auditId = this.auditWaiverData.auditWaiverId;
    this.vehicleService.listWaiverHistory(auditId, params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        //this.waiverHistoryList = res.data[0].waiverHistoryList.auditWaiverData;         
        this.sharedService.vehicleSearchData.yearNo = res.data[0].waiverHistoryList.auditWaiverData.modelYear;
        this.sharedService.seriesName = res.data[0].waiverHistoryList.auditWaiverData.seriesCd;
        this.setModelCodeId(res.data[0].waiverHistoryList.auditWaiverData.modelCode);
        this.auditWaiverData.active = res.data[0].waiverHistoryList.auditWaiverData.activeInd === 'Y' ? true : false;
        this.waivedScopeItems = this.setWaiveScopeItems(res.data[0].waiverHistoryList.auditWaiverData.scope);
        this.getScopeItems();
        this.setExceptionWaivedValue(res.data[0].waiverHistoryList.auditWaiverData.auditWaiverExcp);
        this.auditHistoryList = res.data[0].waiverHistoryList.auditWaiverData.auditHistory;
        this.displayAddWaiveListPanel = true;
        if (!isNullOrUndefined(res.data[0].waiverHistoryList.total)) {
          this.totalRecords = res.data[0].waiverHistoryList.total;
          this.offset = res.data[0].waiverHistoryList.offset;
          this.setPaginationData();
          this.showPagination = true;
        } else {
          this.sharedService.handledNoRecords();
        }
      }
    }, (error: HttpErrorResponse) => {
      console.log('error', error);
    });
  }

  setWaiveScopeItems(data) {
    const temp: any[] = [];
    for (let i = 0; i < data.length; i++) {
      const waivedData = temp.find(item => item.scopeType === data[i].scopeTypeCd);
      if (isNullOrUndefined(waivedData)) {
        const value = this.inScopeItems.find(item => item.scopeType == data[i].scopeTypeCd);
        if (!isNullOrUndefined(value)) {
          temp.push(value);
        }
      }
    }
    return temp;
  }

  setModelCodeId(value) {
    this.sharedService.vehicleSearchData.modelCodeId = { code: value, name: value };
  }

  setExceptionWaivedValue(value) {
    this.auditWaiverData.modifyAction = value;
    if (value === "Waive_Full_Audit") {
      this.fullAuditWaived = true;
    } else if (value === "Waive_Secondary_Audit") {
      this.secondaryAuditWaived = true;
    } else if (value === "Audit_Scope_Modified") {
      this.modifiedWaived = true;
      this.displayPicklist = true;
      this.activeTab = true;
    }
  }
  setPaginationData() {
    this.totalPages = Math.ceil(this.totalRecords / this.limit);
    if (this.totalPages > 5) {
      this.totalPages = 5;
    }
  }

  setAddAuditData() {
    const auditDataModel = new AddAuditData();
    auditDataModel.auditWaiverExcp = this.auditWaiverData.modifyAction;
    auditDataModel.activeInd = this.auditWaiverData.active ? 'Y' : 'N';
    auditDataModel.modelYear = this.auditWaiverData.modelYear;
    auditDataModel.seriesCd = this.auditWaiverData.series;
    auditDataModel.modelCode = this.auditWaiverData.modelCodeId;
    auditDataModel.historyAction = this.setHistoryAction();
    auditDataModel.auditWaiverScope = this.setinsertScopeItems();
    return auditDataModel;
  }

  setHistoryAction() {
    let actionItems;
    if (this.auditWaiverData.modifyAction === "Waive_Full_Audit") {
      actionItems = "Full Waive Scope Items";
    } else if (this.auditWaiverData.modifyAction === "Waive_Secondary_Audit") {
      actionItems = "Secondary Waive Scope Items";
    } else if (this.auditWaiverData.modifyAction === "Audit_Scope_Modified") {
      actionItems = "Modified Waive Scope Items"
    }
    return actionItems;
  }

  searchSideBar(value: any) {
    if (!isNullOrUndefined(value)) {
      this.auditWaiverData.modelYear = value.yearNo;
      if (!isNullOrUndefined(value.seriesId)) {
        this.auditWaiverData.series = value.seriesId.name;
        if (this.auditWaiverData.series !== this.sharedService.seriesName) {
          this.sharedService.seriesName = null;
          this.sharedService.vehicleSearchData.modelCodeId = null;
        }
      } else {
        this.auditWaiverData.series = null;
      }
      if (!isNullOrUndefined(value.modelCodeId)) {
        this.auditWaiverData.modelCodeId = value.modelCodeId.name;
        if (!isNullOrUndefined(this.sharedService.vehicleSearchData.modelCodeId)) {
          if (this.auditWaiverData.modelCodeId !== this.sharedService.vehicleSearchData.modelCodeId.name) {
            this.sharedService.vehicleSearchData.modelCd = null;
          }
        }
      } else {
        this.auditWaiverData.modelCodeId = null;
      }
    }
  }

  addAuditWaiverDetails() {
    this.sharedService.clearPreviousMessages();
    const auditData = this.setAddAuditData();
    if (isNullOrUndefined(auditData.modelYear)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Model Year' });
    } else if (isNullOrUndefined(auditData.seriesCd)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Series' });
    } else if (isNullOrUndefined(auditData.modelCode)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the ModelCode' });
    } else if (isNullOrUndefined(auditData.auditWaiverExcp)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Avaliable Actions' });
    } else if (auditData.auditWaiverExcp === "Audit_Scope_Modified" && this.waivedScopeItems.length == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select the Scope Items' });
    } else {
      this.getDistModelCode(auditData);
    }
  }

  getDistModelCode(auditData) {
    const distributorId = 8;
    const brand = 1;
    if (!isNullOrUndefined(auditData.modelYear)) {
      this.pcpsDistributorService.listMmyDistributorIdModelCodes(distributorId,
        brand, auditData.modelYear, {}).subscribe((res: any) => {
          if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
            const distMMyIdData = res.data[0].models.find(item => item.modelCd == auditData.modelCode);
            if (!isNullOrUndefined(distMMyIdData)) {
              auditData.distMmyId = distMMyIdData.distributorMmyId;
              this.insertAuditWaiverDetails(auditData);
            } else {
              this.messageService.add({ severity: 'error', summary: '', detail: 'DistributorMyId is not avaliable' });
            }
          }
        }, (error: HttpErrorResponse) => {
          this.sharedService.handleHttpError(error);
        })
    }
  }

  insertAuditWaiverDetails(data) {
    console.log("insert data", data);
    this.vehicleService.addAuditWaiver(data).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {   
        this.displayAddWaiveListPanel = false;
        this.getSearchDetails();
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error.error.errorDesc });
      } else {
        console.log("error", error);
      }
    });
  }

  setinsertScopeItems() {
    const scopeArr: Array<AuditScopeData> = [];
    for (let i = 0; i < this.waivedScopeItems.length; i++) {
      if (!isNullOrUndefined(this.waivedScopeItems[i].scopeTypeCd)) {
        const auditScopeModel = new AuditScopeData();
        auditScopeModel.scopeType = this.waivedScopeItems[i].scopeType;
        auditScopeModel.scopeTypeDesc = this.waivedScopeItems[i].scopeTypeCd;
        scopeArr.push(auditScopeModel);
      }
    }
    return scopeArr;
  }

  editAuditWaiverDetails(adminData) {
    this.sharedService.isMandatoryField = true;
    this.fullAuditWaived = false;
    this.secondaryAuditWaived = false;
    this.modifiedWaived = false;
    this.auditWaiverData.auditWaiverId = adminData.auditWaiverId;
    this.auditWaiverData.comment = '';
    this.displayPicklist = false;
    this.activeTab = false;
    this.waivedScopeItems = [];
    this.setInScopeItems();
    this.getWaiverHistoryDetails();
    this.sharedService.isAdminView = false;
    this.sharedService.vehicleSearchData.searchType = "adminview";
  }

  getScopeItems() {
    for (let i = 0; i < this.waivedScopeItems.length; i++) {
      for (let j = 0; j < this.inScopeItems.length; j++) {
        if (this.inScopeItems[j].scopeTypeCd === this.waivedScopeItems[i].scopeTypeCd) {
          this.inScopeItems.splice(j, 1);
          break;
        }
      }
    }
    return this.inScopeItems;
  }

  deleteAuditData() {
    const auditId = this.auditWaiverData.auditWaiverId;
    const deleteAuditData = this.setDeleteAuditData();
    if (!isNullOrUndefined(auditId)) {
      if (!isEmpty(this.auditWaiverData.comment)) {
        this.vehicleService.updateAuditWaiverDetails(deleteAuditData, auditId).subscribe((res: any) => {
          if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
            this.deleteConfirmationPopup = false;
            this.getSearchDetails();
            this.messageService.add({ severity: 'success', summary: '', detail: 'Record deleted succussfully!' });
          }
        }, (error: HttpErrorResponse) => {
          console.log('error', error);
        })
      } else {
        this.messageService.add({ severity: 'error', summary: '', detail: 'please add comments' });
      }
    } else {
      console.log("auditId is not defined");
    }
  }

  setDeleteAuditData() {
    const auditDataModel = new AuditWaiverData();
    auditDataModel.auditWaiverExcp = this.auditWaiverData.ActionDesc;
    auditDataModel.historyNotes = this.auditWaiverData.comment;
    auditDataModel.historyAction = this.setHistoryAction();
    auditDataModel.auditActiveInd = 'N';
    auditDataModel.auditWaiverScope = [];
    return auditDataModel;
  }

  closeAuditData() {
    this.deleteConfirmationPopup = false;
  }

  resetSearchData() {
    this.sharedService.seriesName = null;
    this.sharedService.vehicleSearchData = null;
  }

  sidenavClosed() {
    this.sharedService.isAdminView = true;
  }

  showFilter() {
    if (this.filterAction) {
      this.filterAction = this.checkFilterData();
    } else {
      this.filterAction = true;
    }
  }

  checkFilterData() {
    if ((isNullOrUndefined(this.filtermodelYear) || this.filtermodelYear === "") &&
      (isNullOrUndefined(this.filterseries) || this.filterseries === "") &&
      (isNullOrUndefined(this.filtermodelCode) || this.filtermodelCode === "") &&
      (isNullOrUndefined(this.filterauditExceptionInfo) || this.filterauditExceptionInfo === "") &&
      (isNullOrUndefined(this.filterstatus) || this.filterstatus === "") &&
      (isNullOrUndefined(this.filterlastUpdatedInfo) || this.filterlastUpdatedInfo === "")) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.resetSearchData();
  }
}

