<p-accordion [multiple]="true">
    <div *ngFor="let element of vehicleSubmitList">
        <p-accordionTab>
            <ng-template pTemplate="header">
                <div class="p-d-flex p-flex-column p-flex-md-row">
                    <div class="p-mb-2 p-mr-2">
                        <label class="p-col-fixed cmn-label-font-style">Vin</label>
                        <p-badge [value]="element.vehicleInfo.vin" size="small" class="p-col-fixed" severity="info"
                            styleClass="mr-2">
                        </p-badge>
                    </div>
                    <div class="p-mb-2 p-mr-2">
                        <label class="p-col-fixed cmn-label-font-style">Accessories</label>
                        <p-badge [value]="element.vehicleInfo.accessories" size="small" class="p-col-fixed"
                            severity="info" styleClass="mr-2">
                        </p-badge>
                    </div>
                    <div class="p-mb-2 p-mr-2">
                        <label class="p-col-fixed cmn-label-font-style">Submit Status</label>
                        <span [class]="'uploadStatusBadge status-' + element.submitStatus">
                            {{element.submitStatus}}</span>
                    </div>
                </div>
            </ng-template>
            <p-tabView styleClass="cmn-tabmenu-style" [(activeIndex)]="element.activeTabIndex">
                <p-tabPanel header="Upload" [disabled]="true">
                    <p-table [value]="element.evidenceUploadDataList" styleClass="p-datatable-sm"
                        responsiveLayout="scroll" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [rowsPerPageOptions]="[10,20,30]">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <p-button label="RETRY" icon="pi pi-refresh" *ngIf="element.submitStatus == 'Failed'"
                                    styleClass="p-button-warning cmn-button-style" (click)="submitVehicle(element)">
                                </p-button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="cmn-table-header-style" scope="col">Label Type</th>
                                <th class="cmn-table-header-style" scope="col">Parameter Type</th>
                                <th class="cmn-table-header-style" scope="col">Size</th>
                                <th class="cmn-table-header-style" scope="col">Progress</th>
                                <th class="cmn-table-header-style" scope="col">Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowdata let-rowIndex="rowIndex">
                            <tr>
                                <td class="cmn-table-data-style">{{rowdata.scopeType}}</td>
                                <td class="cmn-table-data-style">{{rowdata.scopeItem}}</td>
                                <td class="cmn-table-data-style">{{rowdata.imgSize}}</td>
                                <td class="cmn-table-data-style">
                                    <p-progressBar [value]="rowdata.uploadProgress"></p-progressBar>
                                </td>
                                <td class="cmn-table-data-style">
                                    <span [class]="'uploadStatusBadge status-' + rowdata.uploadStatus">
                                        {{rowdata.uploadStatus}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="Save" [disabled]="true">
                    <div class="p-d-flex">
                        <p-button label="RETRY" icon="pi pi-refresh" *ngIf="element.submitStatus == 'Failed'"
                            styleClass="p-button-warning cmn-button-style" (click)="saveAuditDataToDB(element)">
                        </p-button>
                    </div>
                    <div *ngIf="element.submitStatus == 'Ready'">
                        <p class="cmn-input-font-style status-SubmitInProgress"> Saving audit data to database ,
                            please wait
                            ! </p>
                    </div>
                    <div *ngIf="element.submitStatus == 'Success'">
                        <p class="cmn-input-font-style status-SubmitSuccess"> Data Saved Successfully</p>
                    </div>
                    <div *ngIf="element.submitStatus == 'Failed'">
                        <p class="cmn-input-font-style status-SubmitFailed"> Error while saving the audit data ,
                            please retry . If issue still persist , please refresh the page and try again. </p>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-accordionTab>
    </div>
</p-accordion>