<div class="p-fluid p-formgrid p-grid">
    <div class="p-field">
        <label class="p-col-fixed cmn-label-font-style">Vin</label>
        <p-badge [value]="vin" size="medium" class="p-col-fixed" severity="info" styleClass="mr-2">
        </p-badge>
    </div>
    <div class="p-field">
        <label class="p-col-fixed cmn-label-font-style">Accessories</label>
        <p-badge [value]="accessories" size="medium" class="p-col-fixed" severity="info" styleClass="mr-2">
        </p-badge>
    </div>
    <div class="p-field">
        <label class="p-col-fixed cmn-label-font-style">Vdc</label>
        <p-badge [value]="vdc" size="medium" class="p-col-fixed" severity="info" styleClass="mr-2">
        </p-badge>
    </div>
    <div class="p-field">
        <label class="p-col-fixed cmn-label-font-style">Model Year</label>
        <p-badge [value]="modelYear" size="medium" class="p-col-fixed" severity="info" styleClass="mr-2">
        </p-badge>
    </div>
    <div class="p-field">
        <label class="p-col-fixed cmn-label-font-style">Model Code</label>
        <p-badge [value]="modelCode" size="medium" class="p-col-fixed" severity="info" styleClass="mr-2">
        </p-badge>
    </div>
</div>
