import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ImageUploadStatus, S3PreSignedUrlOperation, SubmitStatus } from 'src/app/common/app.constants';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { AuditData, VehAssmntData, VehAssmntDataEvidence, VehAssmntEventPlan, VehAssmntPlanEvidence } from 'src/app/core/model/ui/audit-data.model';
import { EvidenceUploadDataModel, VehicleSubmitModel } from 'src/app/core/model/ui/audit-submit.model';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { FileUploadToS3Service } from 'src/app/core/services/ui/file-upload.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { transformScopeItemForUI, transformScopeTypeForUI } from 'src/app/core/utilities/data-transform.util';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';

@Component({
  selector: 'vma-submit-audit',
  templateUrl: './submit-audit.component.html',
  styleUrls: ['./submit-audit.component.scss']
})
export class SubmitAuditComponent implements OnInit {

  vehicleSubmitList: Array<VehicleSubmitModel> = new Array<VehicleSubmitModel>();
  constructor(
    private readonly vehicleService: VehicleService,
    private readonly fileUploadService: FileUploadToS3Service,
    private readonly sharedService: SharedService,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly router: Router
  ) { }
  ngOnInit(): void {
  }
  @Input()
  set auditSubmitList(selectedRows: AuditData[]) {
    this.vehicleSubmitList = new Array<VehicleSubmitModel>();
    selectedRows.forEach((element: AuditData) => {
      this.vehicleSubmitList.push(this.getSubmitDataFromAuditData(element));
    })
    this.submitAuditVehicles();
  }
  getSubmitDataFromAuditData(auditData: AuditData): VehicleSubmitModel {
    let vehicleSubmitModel = new VehicleSubmitModel();
    const evidenceUploadDataList = new Array<EvidenceUploadDataModel>();
    auditData.vehAssmntEventPlan.forEach((assessPlan: VehAssmntEventPlan) => {
      assessPlan.vehAssmntPlanEvidence.forEach(element => {
        if (element.file !== undefined && element.file !== null) {
          evidenceUploadDataList.push(this.getBaseEvidenceUploadModel(assessPlan, element));
          delete element.file;
        }
      });
      assessPlan.vehAssmntData.forEach(assessData => {
        assessData.vehAssmntDataEvidence.forEach(assessEvid => {
          if (assessEvid.file !== undefined && assessEvid.file !== null) {
            evidenceUploadDataList.push(this.getLineByLineEvidenceUploadModel(assessPlan, assessData, assessEvid));
            delete assessEvid.file;
          }
        });
      });
    });
    vehicleSubmitModel = {
      activeTabIndex: 0,
      submitStatus: SubmitStatus.READY,
      vehicleInfo: { vin: auditData.vehDocMaster.vin, accessories: auditData.vehDocMaster.accessories },
      evidenceUploadDataList: evidenceUploadDataList,
      auditData: auditData
    }
    return vehicleSubmitModel;
  }
  submitAuditVehicles() {
    for (const element of this.vehicleSubmitList) {
      this.submitVehicle(element);
    }
  }
  submitVehicle(vehicleSubmitModel: VehicleSubmitModel) {
    vehicleSubmitModel.submitStatus = SubmitStatus.INPROGRESS;
    vehicleSubmitModel.evidenceUploadDataList.forEach(element => {
      if (element.uploadStatus !== ImageUploadStatus.SUCCESS) {
        const params = {
          objectKey: element.evidencePath,
          action: S3PreSignedUrlOperation.Upload
        }
        this.vehicleService.getS3PresignedUrl(params).subscribe(res => {
          this.fileUploadService.uploadFileToS3(res.data[0].data, element.file).subscribe(
            (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                element.uploadStatus = ImageUploadStatus.INPROGRESS;
                element.uploadProgress = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                element.uploadStatus = ImageUploadStatus.SUCCESS;
                if (this.isUploadSuccess(vehicleSubmitModel)) {
                  this.saveAuditDataToDB(vehicleSubmitModel);
                }
              }
            }, (err: any) => {
              element.uploadProgress = 0;
              element.uploadStatus = ImageUploadStatus.FAILED;
              vehicleSubmitModel.submitStatus = SubmitStatus.FAILED;
            });
        }, (error: HttpErrorResponse) => {
          vehicleSubmitModel.submitStatus = SubmitStatus.FAILED;
        });
      }
    });
  }
  isUploadSuccess(vehicleSubmitModel: VehicleSubmitModel): boolean {
    let status = false;
    for (const element of vehicleSubmitModel.evidenceUploadDataList) {
      if (element.uploadStatus === ImageUploadStatus.SUCCESS) {
        status = true;
      } else {
        status = false;
        break;
      }
    }
    return status;
  }
  getBaseEvidenceUploadModel(assessPlan: VehAssmntEventPlan, assessPlanEvid: VehAssmntPlanEvidence): EvidenceUploadDataModel {
    return {
      scopeType: transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd),
      scopeItem: null,
      imgSize: this.readableBytes(assessPlanEvid.file?.size),
      evidencePath: assessPlanEvid.vehAssmntPlanEvidencePath,
      uploadProgress: 0,
      uploadStatus: ImageUploadStatus.READY,
      file: assessPlanEvid.file
    }
  }
  getLineByLineEvidenceUploadModel(assessPlan: VehAssmntEventPlan, assessData: VehAssmntData, assessEvid: VehAssmntDataEvidence): EvidenceUploadDataModel {
    return {
      scopeType: transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd),
      scopeItem: transformScopeItemForUI(assessData.vehAssmntScopeItemCd),
      evidencePath: assessEvid.vehAssmntDataEvidencePath,
      imgSize: this.readableBytes(assessEvid.file?.size),
      uploadProgress: 0,
      uploadStatus: ImageUploadStatus.READY,
      file: assessEvid.file
    }
  }
  readableBytes(bytes: any) {
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return ((bytes / Math.pow(1024, i)) * 1).toFixed(2) + ' ' + sizes[i];
  }
  uploadEvidence(index: number, evidenceUploadData: Array<EvidenceUploadDataModel>) {
    return new Promise((resolve, reject) => {
      const params = {
        objectKey: evidenceUploadData[index].evidencePath,
        action: S3PreSignedUrlOperation.Upload
      }
      this.vehicleService.getS3PresignedUrl(params).subscribe(async res => {
        resolve(await this.uploadFile(index, evidenceUploadData, res.data[0].data));
      }, (error: HttpErrorResponse) => {
        resolve(ImageUploadStatus.FAILED);
      });
    })
  }
  uploadFile(index: number, evidenceUploadData: Array<EvidenceUploadDataModel>, signedUrl: string) {
    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadFileToS3(signedUrl, evidenceUploadData[index].file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            evidenceUploadData[index].uploadStatus = ImageUploadStatus.INPROGRESS;
            evidenceUploadData[index].uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            evidenceUploadData[index].uploadStatus = ImageUploadStatus.SUCCESS;
            resolve(ImageUploadStatus.SUCCESS);
          }
        },
        (err: any) => {
          evidenceUploadData[index].uploadProgress = 0;
          evidenceUploadData[index].uploadStatus = ImageUploadStatus.FAILED;
          resolve(ImageUploadStatus.FAILED);
        });
    })
  }
  saveAuditDataToDB(vehicleSubmitModel: VehicleSubmitModel) {
    vehicleSubmitModel.activeTabIndex = 1;
    this.checkEmptyForVehAssmntDataComment(vehicleSubmitModel.auditData);
    this.vehicleService.updateAudit(vehicleSubmitModel.auditData).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        vehicleSubmitModel.submitStatus = SubmitStatus.SUCCESS;
        this.deleteAuditDataFromIndexedDB(vehicleSubmitModel.auditData.vehAssmntEventId);
      }
    }, (error: HttpErrorResponse) => {
      vehicleSubmitModel.submitStatus = SubmitStatus.FAILED;
    })
  }
  deleteAuditDataFromIndexedDB(vehAssmntEventId: number): void {
    this.indexedDBService.deleteByKey(DbStores.VEH_ASSMNT_EVENT, vehAssmntEventId).subscribe((resp: any) => {
      if (this.isAllDataSubmitted()) {
        this.navigateToSubmittedTab();
      }
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  isAllDataSubmitted(): boolean {
    let status = true;
    for (const element of this.vehicleSubmitList) {
      if (element.submitStatus !== SubmitStatus.SUCCESS) {
        status = false;
        break;
      }
    }
    return status;
  }
  navigateToSubmittedTab() {
    this.sharedService.setVehicleSelectionTabIndex(2);
    this.router.navigate(['audit/vehicle']);
  }

  checkEmptyForVehAssmntDataComment(auditData: AuditData) {
    this.removeAuditData(auditData);
    for (let i = 0; i < auditData.vehAssmntEventPlan.length; i++) {
      for (let j = 0; j < auditData.vehAssmntEventPlan[i].vehAssmntData.length; j++) {
        if (isEmpty(auditData.vehAssmntEventPlan[i].vehAssmntData[j].vehAssmntDataComment)) {
          auditData.vehAssmntEventPlan[i].vehAssmntData[j].vehAssmntDataComment = null;
        }
      }
    }
  }

  removeAuditData(auditData: AuditData) {
    if (!isNullOrUndefined(auditData.vehScopeItems)) {
      let i = 0;
      while (i < auditData.vehAssmntEventPlan.length) {
        const vehAssementData = auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === auditData.vehAssmntEventPlan[i].vehAssmntScopeTypeCd);
        if (!isNullOrUndefined(vehAssementData)) {
          auditData.vehAssmntEventPlan.splice(i, 1);
        } else {
          i++;
        }
      }
      delete auditData.vehScopeItems;
    }
    return auditData.vehAssmntEventPlan;
  }

}
