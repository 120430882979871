
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ConfirmationService } from 'primeng/api';
import { IndexedDBKey, ValidationStatusCdAPI, ValidationStatusCdUI, VehAssmntEventScopeItemAPI, VehAssmntEventScopeTypeAPI, WorkflowStatus } from 'src/app/common/app.constants';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { AuditData, VehAssmntData, VehAssmntDataEvidence, VehAssmntEventPlan, VehAssmntPlanEvidence } from 'src/app/core/model/ui/audit-data.model';
import { AuditVehicleInfoModel } from 'src/app/core/model/ui/export-excel.model';
import { DataVerificationModel } from 'src/app/core/model/ui/input-audit.model';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { transformValidationStatusCdForAPI, transformValidationStatusCdForUI } from 'src/app/core/utilities/data-transform.util';
import Validation from 'src/app/core/utilities/input-audit-form-validation.util';
import { isEmpty, isNull, isNullOrUndefined, isUndefined } from 'src/app/core/utilities/vma-common.util';

@Component({
  selector: 'vma-audit-input',
  templateUrl: './audit-input.component.html',
  styleUrls: ['./audit-input.component.scss']
})
export class AuditInputComponent implements OnInit {

  inputAuditForm: FormGroup;
  vehAssmntEventId: number;
  auditData: AuditData;
  emptyTableArray: any[] = [{}];
  standardFeaturesList: DataVerificationModel[] = [];
  optionalFeaturesList: DataVerificationModel[] = [];
  modelFsaList: DataVerificationModel[] = [];
  colorList: DataVerificationModel[] = [];
  displayMajorSourcesLine2: boolean;
  monroneyBaseEvidence: File[] = [];
  aalaBaseEvidence: File[] = [];
  crBaseEvidence: File[] = [];
  awrBaseEvidence: File[] = [];
  ptag1BaseEvidence: File[] = [];
  ptag2BaseEvidence: File[] = [];
  additionalLabelEvidence: Array<{ file: File, comment: string }> = [];
  emissionsBaseEvidence: File[] = [];
  lineItemModel: DataVerificationModel;
  displayModelFsaMatchPopup: boolean;
  displayModelFsaMismatchPopup: boolean;
  displayModelFsaCommentPopup: boolean;
  displayModelFsaAttachmentsPopup: boolean;

  displayStandardFeaturesMatchPopup: boolean;
  displayStandardFeaturesMismatchPopup: boolean;
  displayStandardFeatureCommentPopup: boolean;
  displayStandardFeatureAttachmentsPopup: boolean;
  displayOptionalFeaturesMatchPopup: boolean;
  displayOptionalFeaturesMismatchPopup: boolean;
  displayOptionalFeatureCommentPopup: boolean;
  displayOptionalFeatureAttachmentsPopup: boolean;
  displayColorMatchPopup: boolean;
  displayColorMismatchPopup: boolean;
  displayColorCommentPopup: boolean;
  displayColorAttachmentsPopup: boolean;
  displayAdditionalFilesCommentPopup: boolean;
  auditVehicleInfo: AuditVehicleInfoModel;
  finalAssemblyPointList: string[] = [];
  countryOfOriginList: string[] = [];
  filteredCountries: string[];
  additionalLabelcomment: string;
  currentFiles: Array<File>;
  // audit waived added 
  monroneyLabelFlag = false;
  monroneyLabelDesc = 'MONRONEY LABEL';
  aalaLabelFlag = false;
  aalaLabelDesc = 'AALA LABEL';
  ptag1Flag = false;
  ptag1Desc = 'PTAG SIDE 1';
  ptag2Flag = false;
  ptag2Desc = 'PTAG SIDE 2';
  crLabelFlag = false;
  crLabelDesc = 'CR Label';
  awrLabelFlag = false;
  awrLabelDesc = 'AWR Label';
  emissionLabelFlag = false;
  emissonLabelDesc = 'Emissions Label';

  constructor(
    private readonly sharedService: SharedService,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly formBuilder: FormBuilder,
    private readonly confirmationService: ConfirmationService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.auditVehicleInfo = new AuditVehicleInfoModel();
    this.initializeInputAuditForm();
    this.vehAssmntEventId = this.sharedService.getVehAssmntEventId();
    if (!isUndefined(this.vehAssmntEventId)) {
      this.fetchAuditDataFromIndexedDB();
      this.fetchFinalAssemblyPointsFromIndexedDB();
      this.fetchCountryOfOriginsFromIndexedDB();
    } else {
      this.navigateToMyAuditInbox();
    }
  }
  setAuditVehicleInformation(auditData: AuditData) {
    this.auditVehicleInfo = new AuditVehicleInfoModel();
    this.auditVehicleInfo.vin = auditData.vehDocMaster.vin;
    this.auditVehicleInfo.accessories = auditData.vehDocMaster.accessories;
    this.auditVehicleInfo.vdc = auditData.vehDocMaster.facilityCd;
    this.auditVehicleInfo.modelYear = auditData.vehDocMaster.modelYear;
    this.auditVehicleInfo.modelCode = auditData.vehDocMaster.modelCode;
    this.auditVehicleInfo.vehAssmntEventId = auditData.vehAssmntEventId;
  }
  fetchFinalAssemblyPointsFromIndexedDB(): void {
    this.indexedDBService.getByKey(DbStores.FINAL_ASSEMBLY_POINT, IndexedDBKey.FinalAssemblyPoints).subscribe((resp: any) => {
      this.finalAssemblyPointList = [];
      if (!isNullOrUndefined(resp) && !isEmpty(resp)) {
        this.transformFinalAssemblyPoints(resp.value);
      }
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  fetchCountryOfOriginsFromIndexedDB(): void {
    this.indexedDBService.getByKey(DbStores.COUNTRY_OF_ORIGIN, IndexedDBKey.CountryOfOrigins).subscribe((resp: any) => {
      this.countryOfOriginList = [];
      if (!isNullOrUndefined(resp) && !isEmpty(resp)) {
        this.transformCountryOfOrigins(resp.value);
      }
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  fetchAuditDataFromIndexedDB(): void {
    this.auditVehicleInfo = new AuditVehicleInfoModel();
    this.indexedDBService.getByKey(DbStores.VEH_ASSMNT_EVENT, this.vehAssmntEventId).subscribe((result: any) => {
      this.auditData = new AuditData();
      if (!isNullOrUndefined(result) && !isEmpty(result)) {
        this.auditData = result;
        this.setAuditVehicleInformation(this.auditData);
        this.patchFormValues();
        this.setAuditWaivedScopeItems();
      }
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    });
  }
  setAuditWaivedScopeItems() {
    this.setMonroneyLabelWaived();
    this.setAalaLabelWaived();
    this.setPtag1Waived();
    this.setPtag2Waived();
    this.setCRLabelWaived();
    this.setAWRLabelWaived();
    this.setEmissionLabelWaived();
  }
  setMonroneyLabelWaived() {
    const monroneyWaived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "MONRONEY_LABEL");
    if (!isNullOrUndefined(monroneyWaived)) {
      this.monroneyLabelFlag = true;
      this.monroneyLabelDesc = 'MONRONEY LABEL WAIVED';
    }
  }
  setAalaLabelWaived() {
    const aalaWaived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "AALA_LABEL");
    if (!isNullOrUndefined(aalaWaived)) {
      this.aalaLabelFlag = true;
      this.aalaLabelDesc = 'AALA LABEL WAIVED';
    }
  }
  setPtag1Waived() {
    const ptag1Waived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "PTAG_1");
    if (!isNullOrUndefined(ptag1Waived)) {
      this.ptag1Flag = true;
      this.ptag1Desc = 'PTAG SIDE 1 WAIVED';
    }
  }
  setPtag2Waived() {
    const ptag2Waived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "PTAG_2");
    if (!isNullOrUndefined(ptag2Waived)) {
      this.ptag2Flag = true;
      this.ptag2Desc = 'PTAG SIDE 2 WAIVED';
    }
  }
  setCRLabelWaived() {
    const crWaived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "CR_LABEL");
    if (!isNullOrUndefined(crWaived)) {
      this.crLabelFlag = true;
      this.crLabelDesc = 'CR Label WAIVED';
    }
  }
  setAWRLabelWaived() {
    const awrWaived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "AWR_LABEL");
    if (!isNullOrUndefined(awrWaived)) {
      this.awrLabelFlag = true;
      this.awrLabelDesc = 'AWR Label WAIVED';
    }
  }
  setEmissionLabelWaived() {
    const emissionWaived = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === "EMISSION_LABEL");
    if (!isNullOrUndefined(emissionWaived)) {
      this.emissionLabelFlag = true;
      this.emissonLabelDesc = 'Emissions Label WAIVED';
    }
  }
  transformFinalAssemblyPoints(idbResp: Array<{ name: string, code: string }>) {
    idbResp.forEach(element => {
      if (element.name !== null) {
        this.finalAssemblyPointList.push(element.name);
      }
    });
  }
  transformCountryOfOrigins(idbResp: Array<{ name: string, code: string }>) {
    idbResp.forEach(element => {
      this.countryOfOriginList.push(element.name);
    });
  }
  setNotVerified(rowdata: DataVerificationModel): DataVerificationModel {
    rowdata.status = ValidationStatusCdUI.NotVerified;
    rowdata.comment = null;
    rowdata.evidences = [];
    return rowdata;
  }
  onModelFsaMatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Match) {
      rowdata = this.setNotVerified(rowdata);
      this.modelFsaList[this.findIndexById(rowdata.assessId, this.modelFsaList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayModelFsaMatchPopup = true;
    }
  }
  onModelFsaMismatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Mismatch) {
      rowdata = this.setNotVerified(rowdata);
      this.modelFsaList[this.findIndexById(rowdata.assessId, this.modelFsaList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayModelFsaMismatchPopup = true;
    }
  }
  viewModelFsaComment(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayModelFsaCommentPopup = true;
  }
  viewModelFsaAttachments(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayModelFsaAttachmentsPopup = true;
  }
  onModelFsaConfirmNotMatch() {
    this.displayModelFsaMatchPopup = false;
  }
  onModelFsaConfirmMatch() {
    this.lineItemModel.status = ValidationStatusCdUI.Match;    
    this.modelFsaList[this.findIndexById(this.lineItemModel.assessId, this.modelFsaList)] = this.lineItemModel;
    this.displayModelFsaMatchPopup = false;
  }
  onModelFsaConfirmNotMismatch() {
    this.displayModelFsaMismatchPopup = false;
  }
  onModelFsaConfirmMismatch() {
    if (this.lineItemModel.comment) {
      this.lineItemModel.status = ValidationStatusCdUI.Mismatch;
      this.modelFsaList[this.findIndexById(this.lineItemModel.assessId, this.modelFsaList)] = this.lineItemModel;
      this.displayModelFsaMismatchPopup = false;
    }
  }
  onRemoveModelFsaAttachment(event: any) {
    this.lineItemModel.evidences.splice(this.lineItemModel.evidences.indexOf(event.file), 1);
    this.modelFsaList[this.findIndexById(this.lineItemModel.assessId, this.modelFsaList)] = this.lineItemModel;
    if (this.lineItemModel.evidences.length === 0) {
      this.displayModelFsaAttachmentsPopup = false;
    }
  }
  onStandardFeaturesMatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Match) {
      rowdata = this.setNotVerified(rowdata);
      this.standardFeaturesList[this.findIndexById(rowdata.assessId, this.standardFeaturesList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayStandardFeaturesMatchPopup = true;
    }
  }
  onStandardFeaturesMismatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Mismatch) {
      rowdata = this.setNotVerified(rowdata);
      this.standardFeaturesList[this.findIndexById(rowdata.assessId, this.standardFeaturesList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayStandardFeaturesMismatchPopup = true;
    }
  }
  onStandardFeaturesConfirmMatch() {
    this.lineItemModel.status = ValidationStatusCdUI.Match;
    this.standardFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.standardFeaturesList)] = this.lineItemModel;
    this.displayStandardFeaturesMatchPopup = false;
  }
  onStandardFeaturesConfirmNotMatch() {
    this.displayStandardFeaturesMatchPopup = false;
  }
  onStandardFeaturesConfirmMismatch() {
    if (this.lineItemModel.comment) {
      this.lineItemModel.status = ValidationStatusCdUI.Mismatch;
      this.standardFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.standardFeaturesList)] = this.lineItemModel;
      this.displayStandardFeaturesMismatchPopup = false;
    }
  }
  onStandardFeaturesConfirmNotMismatch() {
    this.displayStandardFeaturesMismatchPopup = false;
  }
  viewStandardFeaturesComment(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayStandardFeatureCommentPopup = true;
  }
  viewStandardFeaturesAttachments(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayStandardFeatureAttachmentsPopup = true;
  }
  onRemoveStandardFeaturesAttachment(event: any) {
    this.lineItemModel.evidences.splice(this.lineItemModel.evidences.indexOf(event.file), 1);
    this.standardFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.standardFeaturesList)] = this.lineItemModel;
    if (this.lineItemModel.evidences.length === 0) {
      this.displayStandardFeatureAttachmentsPopup = false;
    }
  }
  onOptionalFeaturesMatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Match) {
      rowdata = this.setNotVerified(rowdata);
      this.optionalFeaturesList[this.findIndexById(rowdata.assessId, this.optionalFeaturesList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayOptionalFeaturesMatchPopup = true;
    }
  }
  onOptionalFeaturesMismatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Mismatch) {
      rowdata = this.setNotVerified(rowdata);
      this.optionalFeaturesList[this.findIndexById(rowdata.assessId, this.optionalFeaturesList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayOptionalFeaturesMismatchPopup = true;
    }
  }
  onOptionalFeaturesConfirmMatch() {
    this.lineItemModel.status = ValidationStatusCdUI.Match;
    this.optionalFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.optionalFeaturesList)] = this.lineItemModel;
    this.displayOptionalFeaturesMatchPopup = false;
  }
  onOptionalFeaturesConfirmNotMatch() {
    this.displayOptionalFeaturesMatchPopup = false;
  }
  onOptionalFeaturesConfirmMismatch() {
    if (this.lineItemModel.comment) {
      this.lineItemModel.status = ValidationStatusCdUI.Mismatch;
      this.optionalFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.optionalFeaturesList)] = this.lineItemModel;
      this.displayOptionalFeaturesMismatchPopup = false;
    }
  }
  onOptionalFeaturesConfirmNotMismatch() {
    this.displayOptionalFeaturesMismatchPopup = false;
  }
  viewOptionalFeaturesComment(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayOptionalFeatureCommentPopup = true;
  }
  viewOptionalFeaturesAttachments(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayOptionalFeatureAttachmentsPopup = true;
  }
  onRemoveOptionalFeaturesAttachment(event: any) {
    this.lineItemModel.evidences.splice(this.lineItemModel.evidences.indexOf(event.file), 1);
    this.optionalFeaturesList[this.findIndexById(this.lineItemModel.assessId, this.optionalFeaturesList)] = this.lineItemModel;
    if (this.lineItemModel.evidences.length === 0) {
      this.displayOptionalFeatureAttachmentsPopup = false;
    }
  }
  onColorMatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Match) {
      rowdata = this.setNotVerified(rowdata);
      this.colorList[this.findIndexById(rowdata.assessId, this.colorList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayColorMatchPopup = true;
    }
  }
  onColorMismatch(rowdata: DataVerificationModel) {
    if (rowdata.status === ValidationStatusCdUI.Mismatch) {
      rowdata = this.setNotVerified(rowdata);
      this.colorList[this.findIndexById(rowdata.assessId, this.colorList)] = rowdata;
    } else {
      this.lineItemModel = { ...rowdata };
      this.lineItemModel.comment = null;
      this.displayColorMismatchPopup = true;
    }
  }
  onColorConfirmMatch() {
    this.lineItemModel.status = ValidationStatusCdUI.Match;
    this.colorList[this.findIndexById(this.lineItemModel.assessId, this.colorList)] = this.lineItemModel;
    this.displayColorMatchPopup = false;
  }
  onColorConfirmNotMatch() {
    this.displayColorMatchPopup = false;
  }
  onColorConfirmMismatch() {
    if (this.lineItemModel.comment) {
      this.lineItemModel.status = ValidationStatusCdUI.Mismatch;
      this.colorList[0] = this.lineItemModel;
      this.displayColorMismatchPopup = false;
    }
  }
  onColorConfirmNotMismatch() {
    this.displayColorMismatchPopup = false;
  }
  viewColorComment(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayColorCommentPopup = true;
  }
  viewColorAttachments(rowdata: DataVerificationModel) {
    this.lineItemModel = { ...rowdata };
    this.displayColorAttachmentsPopup = true;
  }
  onRemoveColorAttachment(event: any) {
    this.lineItemModel.evidences.splice(this.lineItemModel.evidences.indexOf(event.file), 1);
    this.colorList[this.findIndexById(this.lineItemModel.assessId, this.colorList)] = this.lineItemModel;
    if (this.lineItemModel.evidences.length === 0) {
      this.displayColorAttachmentsPopup = false;
    }
  }
  onAttachBaseFiles(event: any, labelType: any) {
    if (labelType === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
      this.monroneyBaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
      this.aalaBaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.PTAG_1) {
      this.ptag1BaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.PTAG_2) {
      this.ptag2BaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.AWR_LABEL) {
      this.awrBaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.CR_LABEL) {
      this.crBaseEvidence = event.currentFiles;
    } else if (labelType === VehAssmntEventScopeTypeAPI.EMISSION_LABEL) {
      this.emissionsBaseEvidence = event.currentFiles;
    }
  }
  onRemoveBaseFiles(labelType: any) {
    this.deleteBaseEvidenceIfAlreadyExist(labelType);
  }
  deleteBaseEvidenceIfAlreadyExist(labelType: VehAssmntEventScopeTypeAPI) {
    if (labelType === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
      this.monroneyBaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
      this.aalaBaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.PTAG_1) {
      this.ptag1BaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.PTAG_2) {
      this.ptag2BaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.AWR_LABEL) {
      this.awrBaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.CR_LABEL) {
      this.crBaseEvidence = [];
    } else if (labelType === VehAssmntEventScopeTypeAPI.EMISSION_LABEL) {
      this.emissionsBaseEvidence = [];
    }
  }
  onAttachFiles(event: any) {
    this.lineItemModel.evidences = event.currentFiles;
  }  
  findIndexById(id: number, dataList: Array<DataVerificationModel>): number {
    let index = -1;
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].assessId === id) {
        index = i;
        break;
      }
    }
    return index;
  }
  getBaseEvidence(vehAssmntPlanEvidences: Array<VehAssmntPlanEvidence>) {
    const tempArray: any = [];
    vehAssmntPlanEvidences.forEach(element => {
      if (element.file !== undefined || element.file !== null) {
        tempArray.push(element.file)
      }
    });
    return tempArray;
  }
  getAdditionalLabelEvidence(vehAssmntPlanEvidences: Array<VehAssmntPlanEvidence>) {
    const tempArray: any = [];
    vehAssmntPlanEvidences.forEach(element => {
      if (element.file !== undefined || element.file !== null) {
        tempArray.push({ file: element.file, comment: element.vehAssmntPlanEvidenceComment })
      }
    });
    return tempArray;
  }
  patchFormValues() {
    this.auditData.vehAssmntEventPlan.forEach((element: VehAssmntEventPlan) => {
      if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
        this.monroneyBaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
        element.vehAssmntData.forEach((item: VehAssmntData) => {
          if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MONRONEY_LABEL_VERSION) {
            this.MlVersion?.setValue(item.vehAssmntActualData);
            this.MlVersion?.setValidators([Validators.required, Validation.validateMlVersion(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
            this.MlFinalAssemblyPoint?.setValue(item.vehAssmntActualData);
            this.MlFinalAssemblyPoint?.setValidators([Validators.required, Validation.validateMlFinalAssemblyPoint(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.STANDARD_EQUIP) {
            this.createStandardFeaturesList(item);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.OPTIONAL_EQUIP) {
            this.createOptionalFeaturesList(item);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.COLOR) {
            this.createColorList(item);
          }
        });
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
        this.aalaBaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
        let majorSourcesOfForiegnPartsContentCountry1SourceValue: any = null;
        let majorSourcesOfForiegnPartsContentPercent1SourceValue: any = null;
        let majorSourcesOfForiegnPartsContentCountry2SourceValue: any = null;
        let majorSourcesOfForiegnPartsContentPercent2SourceValue: any = null;

        element.vehAssmntData.forEach((item: VehAssmntData) => {
          if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.US_CAN_PRT_CNTNT) {
            this.UsCanadaPartsContent?.setValue(item.vehAssmntActualData);
            this.UsCanadaPartsContent?.setValidators([Validators.required, Validation.validateUsCanadaPartsContent(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
            this.AalaFinalAssemblyPoint?.setValue(item.vehAssmntActualData);
            this.AalaFinalAssemblyPoint?.setValidators([Validators.required, Validation.validateAALAFinalAssemblyPoint(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_ENG_PRT) {
            this.CountryOfOriginEngineParts?.setValue(item.vehAssmntActualData);
            this.CountryOfOriginEngineParts?.setValidators([Validators.required, Validation.validateCountryOfOriginEngineParts(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_TRANS_PRT) {
            this.CountryOfOriginTransmissionParts?.setValue(item.vehAssmntActualData);
            this.CountryOfOriginTransmissionParts?.setValidators([Validators.required, Validation.validateCountryOfOriginTransmissionParts(item.nvsDataReference)]);
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_1) {
            this.MajorSourcesOfForiegnPartsContentCountry1?.setValue(item.vehAssmntActualData);
            this.MajorSourcesOfForiegnPartsContentCountry1?.setValidators([Validators.required]);
            majorSourcesOfForiegnPartsContentCountry1SourceValue = item.nvsDataReference;
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_1) {
            this.MajorSourcesOfForiegnPartsContentPercent1?.setValue(item.vehAssmntActualData);
            this.MajorSourcesOfForiegnPartsContentPercent1?.setValidators([Validators.required]);
            majorSourcesOfForiegnPartsContentPercent1SourceValue = item.nvsDataReference;
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2) {
            this.MajorSourcesOfForiegnPartsContentCountry2?.setValue(item.vehAssmntActualData);
            this.MajorSourcesOfForiegnPartsContentCountry2?.setValidators([Validators.required]);
            majorSourcesOfForiegnPartsContentCountry2SourceValue = item.nvsDataReference;
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2) {
            this.MajorSourcesOfForiegnPartsContentPercent2?.setValue(item.vehAssmntActualData);
            this.MajorSourcesOfForiegnPartsContentPercent2?.setValidators([Validators.required]);
            majorSourcesOfForiegnPartsContentPercent2SourceValue = item.nvsDataReference;
          } else if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.TRANS_SRL_NUM) {
            this.TransmissionSerialNumber?.setValue(item.nvsDataReference);
          }
        });

        this.MajorSourcesOfForiegnPartsContentLine1?.setValidators([Validation.validateMajorSourcesLine1(majorSourcesOfForiegnPartsContentCountry1SourceValue, majorSourcesOfForiegnPartsContentPercent1SourceValue)]);
        this.MajorSourcesOfForiegnPartsContentLine2?.setValidators([Validation.validateMajorSourcesLine2(majorSourcesOfForiegnPartsContentCountry2SourceValue, majorSourcesOfForiegnPartsContentPercent2SourceValue)]);
        this.determineMajorSourcesOfForiegnPartsContentLine2Visibility();

      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.CR_LABEL) {
        this.crBaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
        element.vehAssmntData.forEach((item: VehAssmntData) => {
          if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.GVWR) {
            this.GVWR?.setValue(item.vehAssmntActualData);
            this.GVWR?.setValidators([Validators.required, Validation.validateGvwr(item.nvsDataReference)]);
          }
        });
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.AWR_LABEL) {
        this.awrBaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.PTAG_1) {
        this.ptag1BaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
        element.vehAssmntData.forEach((item: VehAssmntData) => {
          if (item.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MODEL_FSA) {
            this.createModelFsaList(item);
          }
        });
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.PTAG_2) {
        this.ptag2BaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.ADDITIONAL_EVID) {
        this.additionalLabelEvidence = this.getAdditionalLabelEvidence(element.vehAssmntPlanEvidence);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.EMISSION_LABEL) {
        this.emissionsBaseEvidence = this.getBaseEvidence(element.vehAssmntPlanEvidence);
      }
    });
  }
  filterCountry(event: any) {
    const filtered: any[] = [];
    const query = event.query;
    this.countryOfOriginList.forEach(element => {
      if (element.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(element);
      }
    });
    this.filteredCountries = filtered;
  }
  createStandardFeaturesList(assessData: VehAssmntData) {
    this.standardFeaturesList.push(this.getAssessData(assessData, VehAssmntEventScopeItemAPI.STANDARD_EQUIP));
  }
  createModelFsaList(assessData: VehAssmntData) {
    this.modelFsaList.push(this.getAssessData(assessData, VehAssmntEventScopeItemAPI.MODEL_FSA));
  }

  createOptionalFeaturesList(assessData: VehAssmntData) {
    this.optionalFeaturesList.push(this.getAssessData(assessData, VehAssmntEventScopeItemAPI.OPTIONAL_EQUIP));
  }
  createColorList(assessData: VehAssmntData) {
    this.colorList.push(this.getAssessData(assessData, VehAssmntEventScopeItemAPI.COLOR));
  }
  getEvidences(assessDataEvid: Array<VehAssmntDataEvidence>): Array<File> {
    const evidences: File[] = [];
    assessDataEvid.forEach((element: VehAssmntDataEvidence) => {
      if (element.file !== undefined) {
        evidences.push(element.file);
      }
    });
    return evidences;
  }
  getAssessData(assessData: VehAssmntData, scopeItem: VehAssmntEventScopeItemAPI): DataVerificationModel {
    return {
      assessId: assessData.vehAssmntDataId,
      type: scopeItem,
      data: assessData.nvsDataReference,
      status: transformValidationStatusCdForUI(assessData.vehAssmntValidationStatusCd),
      comment: assessData.vehAssmntDataComment,
      evidences: this.getEvidences(assessData.vehAssmntDataEvidence)
    }
  }
  initializeInputAuditForm() {
    this.inputAuditForm = this.formBuilder.group(
      {
        monroneyLabel: this.createMonroneyFormGroup(),
        aalaLabel: this.createAalaFormGroup(),
        crLabel: this.createCrLabelFormGroup()
      }
    )
  }
  createMonroneyFormGroup() {
    return this.formBuilder.group({
      mlVersion: [null, [Validators.required]],
      finalAssemblyPoint: [null, [Validators.required]]
    })
  }
  createAalaFormGroup() {
    return this.formBuilder.group({
      usCanadaPartsContent: [null, [Validators.required]],
      finalAssemblyPoint: [null, [Validators.required]],
      majorSourcesOfForeignPartsContentLine_1: this.formBuilder.group({
        country: [null, [Validators.required]],
        percent: [null, [Validators.required]],
      }),
      majorSourcesOfForeignPartsContentLine_2: this.formBuilder.group({
        country: [null, [Validators.required]],
        percent: [null, [Validators.required]],
      }),
      countryOfOriginEngineParts: [null, [Validators.required]],
      countryOfOriginTransmissionParts: [null, [Validators.required]],
      transmissionSerialNumber: [null]
    })
  }
  createCrLabelFormGroup() {
    return this.formBuilder.group({
      gvwr: [null, [Validators.required]]
    })
  }
  get MlVersion() {
    return this.inputAuditForm.get('monroneyLabel.mlVersion');
  }
  get MlFinalAssemblyPoint() {
    return this.inputAuditForm.get('monroneyLabel.finalAssemblyPoint');
  }
  get UsCanadaPartsContent() {
    return this.inputAuditForm.get('aalaLabel.usCanadaPartsContent');
  }
  get AalaFinalAssemblyPoint() {
    return this.inputAuditForm.get('aalaLabel.finalAssemblyPoint');
  }
  get CountryOfOriginEngineParts() {
    return this.inputAuditForm.get('aalaLabel.countryOfOriginEngineParts');
  }
  get CountryOfOriginTransmissionParts() {
    return this.inputAuditForm.get('aalaLabel.countryOfOriginTransmissionParts');
  }
  get MajorSourcesOfForiegnPartsContentLine1() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_1');
  }
  get MajorSourcesOfForiegnPartsContentLine2() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_2');
  }
  get MajorSourcesOfForiegnPartsContentCountry1() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_1.country');
  }
  get MajorSourcesOfForiegnPartsContentPercent1() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_1.percent');
  }
  get MajorSourcesOfForiegnPartsContentCountry2() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_2.country');
  }
  get MajorSourcesOfForiegnPartsContentPercent2() {
    return this.inputAuditForm.get('aalaLabel.majorSourcesOfForeignPartsContentLine_2.percent');
  }
  get TransmissionSerialNumber() {
    return this.inputAuditForm.get('aalaLabel.transmissionSerialNumber');
  }
  get GVWR() {
    return this.inputAuditForm.get('crLabel.gvwr');
  }
  isAllRequiredDataEntered(): boolean {
    let status = false;
    const isAllMandatoryFieldsEntered: boolean = this.isAllMandatoryFieldsEntered();
    const isAllStandardFeauturesLinesVerified: boolean = this.isAllLinesVerified(this.standardFeaturesList);
    const isAllOptionalFeauturesLinesVerified: boolean = this.isAllLinesVerified(this.optionalFeaturesList);
    const isColorVerified: boolean = this.isAllLinesVerified(this.colorList);
    const isAllModelFsaLinesVerified: boolean = this.isAllLinesVerified(this.modelFsaList);
    const isAllMandatoryLabelsAttached: boolean = this.isAllMandatoryLabelsAttached();
    if (isAllMandatoryFieldsEntered && isAllModelFsaLinesVerified && isAllMandatoryLabelsAttached && isAllStandardFeauturesLinesVerified && isAllOptionalFeauturesLinesVerified && isColorVerified) {
      status = true;
    }
    return status;
  }
  isAllMandatoryLabelsAttached(): boolean {
    let status = false;
    if (!isEmpty(this.monroneyBaseEvidence) && !isEmpty(this.aalaBaseEvidence)
      && !isEmpty(this.crBaseEvidence) && !isEmpty(this.awrBaseEvidence) && !isEmpty(this.ptag1BaseEvidence)
      && !isEmpty(this.ptag2BaseEvidence)) {
      status = true;
    }
    return status;
  }
  isAllMandatoryFieldsEntered(): boolean {
    let status = true;
    if (this.MlVersion?.value === null || this.MlVersion?.value === "" || this.MlFinalAssemblyPoint?.value === null || this.MlFinalAssemblyPoint?.value === ""
      || this.UsCanadaPartsContent?.value === null || this.UsCanadaPartsContent?.value === "" || this.CountryOfOriginEngineParts?.value === null || this.CountryOfOriginEngineParts?.value === ""
      || this.CountryOfOriginTransmissionParts?.value === null || this.CountryOfOriginTransmissionParts?.value === "" || this.GVWR?.value === null || this.GVWR?.value === ""
      || this.AalaFinalAssemblyPoint?.value === null || this.AalaFinalAssemblyPoint?.value === ""
      || this.MajorSourcesOfForiegnPartsContentCountry1?.value === null || this.MajorSourcesOfForiegnPartsContentPercent1?.value === "") {
      status = false;
    }
    return status;
  }
  isAuditDataHavingMismatch(): boolean {
    let status = false;
    if (this.inputAuditForm.invalid) {
      status = true;
    }
    return status;
  }
  isAllLinesVerified(dataList: DataVerificationModel[]): boolean {
    let status = true;
    for (const element of dataList) {
      if (element.status === ValidationStatusCdUI.NotVerified) {
        status = false;
        break;
      }
    }
    return status;
  }
  partialSave(): void {
    this.confirmationService.confirm({
      header: "Partial Audit Save",
      message: 'Mandatory fields are missing , do you want to partially Save the Audit Form ?',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: "accept",
      rejectButtonStyleClass: "p-button-text cmn-button-style",
      acceptButtonStyleClass: "cmn-button-style",
      closeOnEscape: false,
      accept: () => {
        this.sharedService.setAuditInboxTabIndex(2);
        this.setWorkfloWStatusCd(WorkflowStatus.PARTIALLY_AUDITED);
        this.updateAuditDataInIndexedDB();
      }
    });
  }
  saveWithErrors() {
    this.confirmationService.confirm({
      header: "Save with Errors",
      message: 'Certain values are not matching with source system . Do you still want to save audit form ?',
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: false,
      rejectButtonStyleClass: "p-button-text cmn-button-style",
      acceptButtonStyleClass: "cmn-button-style",
      accept: () => {
        this.sharedService.setAuditInboxTabIndex(1);
        this.setWorkfloWStatusCd(WorkflowStatus.READY_TO_SUBMIT);
        this.updateAuditDataInIndexedDB();
      }
    });
  }
  back() {
    if (this.inputAuditForm.dirty && this.inputAuditForm.touched) {
      this.confirmationService.confirm({
        header: "Confirmation",
        icon: 'pi pi-exclamation-triangle',
        message: 'Data will be lost if you go back without saving. Are you sure want to proceed?',
        defaultFocus: "accept",
        rejectButtonStyleClass: "p-button-text cmn-button-style",
        acceptButtonStyleClass: "cmn-button-style",
        closeOnEscape: false,
        accept: () => {
          this.navigateToMyAuditInbox();
        }
      })
    } else {
      this.navigateToMyAuditInbox();
    }
  }
  reset() {
    this.confirmationService.confirm({
      header: "Page Reset Confirmation",
      message: 'Full page reset will clear out all inputs entered , line by line verifications and attachment . Are you sure want to proceed ?',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: "accept",
      rejectButtonStyleClass: "p-button-text cmn-input-font-style",
      acceptButtonStyleClass: "cmn-input-font-style",
      closeOnEscape: false,
      accept: () => {
        this.resetAllInputs();
      }
    });
  }
  resetAllInputs() {
    this.inputAuditForm.reset();
    this.standardFeaturesList = this.resetLineByLineVerificationSection(this.standardFeaturesList);
    this.optionalFeaturesList = this.resetLineByLineVerificationSection(this.optionalFeaturesList);
    this.colorList = this.resetLineByLineVerificationSection(this.colorList);
    this.removeAttachments();
  }
  removeAttachments() {
    this.monroneyBaseEvidence = [];
    this.aalaBaseEvidence = [];
    this.ptag1BaseEvidence = [];
    this.ptag2BaseEvidence = [];
    this.awrBaseEvidence = [];
    this.crBaseEvidence = [];
    this.additionalLabelEvidence = [];
    this.emissionsBaseEvidence = [];
  }
  resetLineByLineVerificationSection(dataList: DataVerificationModel[]) {
    dataList.forEach(element => {
      element.comment = null;
      element.evidences = new Array<File>();
      element.status = ValidationStatusCdUI.NotVerified
    });
    return dataList;
  }
  navigateToMyAuditInbox() {
    this.router.navigate(['audit/inbox']);
  }
  setWorkfloWStatusCd(statusCd: WorkflowStatus): void {
    this.auditData.vehAssmntEventWorkflow.workflowStatusCd = statusCd;
  }
  save() {
    this.setInputValuesToAuditData();
    if (this.checkLabelWaivedData()) {
      this.waivedFormValidtion();
    } else {
      this.auditFormValidation();
    }
  }
  waivedFormValidtion() {
    if (this.checkWaivedAuditFormValidation()) {
      this.partialSave();
    } else {
      this.saveWithErrors();
    }
  }
  auditFormValidation() {
    if (!this.isAllRequiredDataEntered()) {
      this.partialSave();
    } else {
      if (this.isAuditDataHavingMismatch()) {
        this.saveWithErrors();
      } else {
        this.sharedService.setAuditInboxTabIndex(1);
        this.setWorkfloWStatusCd(WorkflowStatus.READY_TO_SUBMIT);
        this.updateAuditDataInIndexedDB();
      }
    }
  }
  skippedMonroneyValidation() {
    let status = false;
    const isAllStandardFeauturesLinesVerified: boolean = this.isAllLinesVerified(this.standardFeaturesList);
    const isAllOptionalFeauturesLinesVerified: boolean = this.isAllLinesVerified(this.optionalFeaturesList);
    const isColorVerified: boolean = this.isAllLinesVerified(this.colorList);
    if (!this.monroneyLabelFlag && (this.MlVersion?.value === null || this.MlVersion?.value === "" ||
      this.MlFinalAssemblyPoint?.value === null || this.MlFinalAssemblyPoint?.value === "" ||
      !isAllStandardFeauturesLinesVerified || !isAllOptionalFeauturesLinesVerified || !isColorVerified ||
      isEmpty(this.monroneyBaseEvidence))) {
      status = true;
    }
    return status;
  }

  skippedAalabelValidation() {
    let status = false;
    if (!this.aalaLabelFlag && (this.UsCanadaPartsContent?.value === null || this.UsCanadaPartsContent?.value === "" ||
      this.CountryOfOriginEngineParts?.value === null || this.CountryOfOriginEngineParts?.value === "" ||
      this.CountryOfOriginTransmissionParts?.value === null || this.CountryOfOriginTransmissionParts?.value === "" ||
      this.AalaFinalAssemblyPoint?.value === null || this.AalaFinalAssemblyPoint?.value === "" ||
      isEmpty(this.aalaBaseEvidence))) {
      status = true;
    }
    return status;
  }

  skippedPtag1Validation() {
    let status = false;
    const isAllModelFsaLinesVerified: boolean = this.isAllLinesVerified(this.modelFsaList);
    if (!this.ptag1Flag && (!isAllModelFsaLinesVerified || isEmpty(this.ptag1BaseEvidence))) {
      status = true;
    }
    return status;
  }

  skippedPtag2Validation() {
    let status = false;
    if (!this.ptag2Flag && (isEmpty(this.ptag2BaseEvidence))) {
      status = true;
    }
    return status;
  }

  skippedCRValidation() {
    let status = false;
    if (!this.crLabelFlag && (this.GVWR?.value === null || this.GVWR?.value === "" || isEmpty(this.crBaseEvidence))) {
      status = true;
    }
    return status;
  }

  skippedAWRValidation() {
    let status = false;
    if (!this.awrLabelFlag && (isEmpty(this.awrBaseEvidence))) {
      status = true;
    }
    return status;
  }

  checkWaivedAuditFormValidation() {
    const mlValidation = this.skippedMonroneyValidation();
    const alValidation = this.skippedAalabelValidation();
    const ptag1Validation = this.skippedPtag1Validation();
    const ptag2Validation = this.skippedPtag2Validation();
    const crValidation = this.skippedCRValidation();
    const awrValidation = this.skippedAWRValidation();
    if (mlValidation || alValidation || ptag1Validation || ptag2Validation || crValidation || awrValidation) {
      return true;
    } else {
      return false;
    }
  }
  checkLabelWaivedData() {
    if (this.monroneyLabelFlag || this.aalaLabelFlag || this.ptag1Flag || this.ptag2Flag
      || this.crLabelFlag || this.awrLabelFlag || this.emissionLabelFlag) {
      return true;
    } else {
      return false;
    }
  }
  navigateToInbox() {
    this.router.navigate(['audit/inbox']);
  }
  updateAuditDataInIndexedDB() {
    this.indexedDBService.update(DbStores.VEH_ASSMNT_EVENT, this.auditData).subscribe((result: any) => {
      this.navigateToInbox();
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  setInputValuesToAuditData() {
    this.auditData.vehAssmntEventPlan.forEach((assessPlan: VehAssmntEventPlan) => {
      if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
        this.setMonroneyInputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
        this.setAalaInputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.AWR_LABEL) {
        this.setAwrInputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.CR_LABEL) {
        this.setCrInputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.PTAG_1) {
        this.setPtag1InputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.PTAG_2) {
        this.setPtag2InputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.ADDITIONAL_EVID) {
        this.setAdditionalEvidenceInputValues(assessPlan);
      } else if (assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.EMISSION_LABEL) {
        this.setEmissionsInputValues(assessPlan);
      }
    });
  }
  setMonroneyInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.monroneyBaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MONRONEY_LABEL_VERSION) {
        assessData.vehAssmntActualData = this.MlVersion?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
        assessData.vehAssmntActualData = this.MlFinalAssemblyPoint?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.COLOR) {
        this.setVerificationInputValues(this.colorList, assessData, assessPlan);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.STANDARD_EQUIP) {
        this.setVerificationInputValues(this.standardFeaturesList, assessData, assessPlan);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.OPTIONAL_EQUIP) {
        this.setVerificationInputValues(this.optionalFeaturesList, assessData, assessPlan);
      }
    });
  }
  setAalaInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.aalaBaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.US_CAN_PRT_CNTNT) {
        assessData.vehAssmntActualData = this.UsCanadaPartsContent?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
        assessData.vehAssmntActualData = this.AalaFinalAssemblyPoint?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_ENG_PRT) {
        assessData.vehAssmntActualData = this.CountryOfOriginEngineParts?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_TRANS_PRT) {
        assessData.vehAssmntActualData = this.CountryOfOriginTransmissionParts?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_1) {
        assessData.vehAssmntActualData = this.MajorSourcesOfForiegnPartsContentCountry1?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_1) {
        assessData.vehAssmntActualData = this.MajorSourcesOfForiegnPartsContentPercent1?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2) {
        assessData.vehAssmntActualData = this.MajorSourcesOfForiegnPartsContentCountry2?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2) {
        assessData.vehAssmntActualData = this.MajorSourcesOfForiegnPartsContentPercent2?.value;
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.TRANS_SRL_NUM) {
        assessData.vehAssmntActualData = this.TransmissionSerialNumber?.value;
      }
    });

    if (!this.checkIfSecondForeignCountryAdded(assessPlan)) {
      if (!isNull(this.MajorSourcesOfForiegnPartsContentCountry2?.value)) {
        assessPlan.vehAssmntData.push(this.setVehAssmntData(assessPlan, VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2, this.MajorSourcesOfForiegnPartsContentCountry2?.value));
        if (!isNull(this.MajorSourcesOfForiegnPartsContentPercent2?.value)) {
          assessPlan.vehAssmntData.push(this.setVehAssmntData(assessPlan, VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2, this.MajorSourcesOfForiegnPartsContentPercent2?.value));
        }
      }
    }
  }
  checkIfSecondForeignCountryAdded(assessPlan: VehAssmntEventPlan): boolean {
    let status = false;
    for (const element of assessPlan.vehAssmntData) {
      if (element.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2) {
        status = true;
      }
    }
    return status;
  }

  setCrInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.crBaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.GVWR) {
        assessData.vehAssmntActualData = this.GVWR?.value;
      }
    });
  }
  setAwrInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.awrBaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
  }
  setPtag1InputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.ptag1BaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MODEL_FSA) {
        this.setVerificationInputValues(this.modelFsaList, assessData, assessPlan);
      }
    });
  }
  setPtag2InputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.ptag2BaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
  }
  setAdditionalEvidenceInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.additionalLabelEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element.file, element.comment))
    });
  }
  setEmissionsInputValues(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntPlanEvidence = [];
    this.emissionsBaseEvidence.forEach((element, index) => {
      assessPlan.vehAssmntPlanEvidence.push(this.setVehAssmntPlanEvidence(assessPlan, index, element))
    });
  }
  setVehAssmntData(assessPlan: VehAssmntEventPlan, vehAssmntScopeItemCd: VehAssmntEventScopeItemAPI, inputdata: any): VehAssmntData {
    const vehAssmntData = new VehAssmntData();
    vehAssmntData.vehAssmntDataId = null;
    vehAssmntData.vehAssmntEventPlanId = assessPlan.vehAssmntEventPlanId;
    vehAssmntData.vehAssmntScopeItemCd = vehAssmntScopeItemCd;
    vehAssmntData.vehAssmntValidationStatusCd = ValidationStatusCdAPI.NOT_VERIFIED;
    vehAssmntData.nvsDataReference = "";
    vehAssmntData.vehAssmntActualData = inputdata;
    vehAssmntData.vehAssmntDataComment = null;
    vehAssmntData.vehAssmntDataEvidence = [];
    return vehAssmntData;
  }
  setVehAssmntDataEvidence(assessPlan: VehAssmntEventPlan, assessData: VehAssmntData, file: File, index: number): VehAssmntDataEvidence {
    const vehAssmntDataEvidence = new VehAssmntDataEvidence();
    vehAssmntDataEvidence.vehAssmntDataEvidenceComment = null;
    vehAssmntDataEvidence.vehAssmntDataEvidenceId = null;
    vehAssmntDataEvidence.vehAssmntDataId = assessData.vehAssmntDataId;
    vehAssmntDataEvidence.vehAssmntDataEvidenceUploadTs = null;
    vehAssmntDataEvidence.vehAssmntDataEvidencePath = this.generateDataEvidencePath(assessPlan, assessData, file, index);
    vehAssmntDataEvidence.file = file;
    return vehAssmntDataEvidence;
  }
  setVehAssmntPlanEvidence(assessPlan: VehAssmntEventPlan, index: number, file: File, comment?: string): VehAssmntPlanEvidence {
    const vehAssmntPlanEvidence = new VehAssmntPlanEvidence();
    vehAssmntPlanEvidence.vehAssmntEventPlanId = assessPlan.vehAssmntEventPlanId;
    vehAssmntPlanEvidence.vehAssmntPlanEvidenceComment = null;
    if (comment !== undefined && comment !== "") {
      vehAssmntPlanEvidence.vehAssmntPlanEvidenceComment = comment;
    }
    vehAssmntPlanEvidence.vehAssmntPlanEvidenceId = null;
    vehAssmntPlanEvidence.vehAssmntPlanEvidenceUploadTs = null;
    vehAssmntPlanEvidence.vehAssmntPlanEvidencePath = this.generateBaseEvidencePath(assessPlan, file, index);
    vehAssmntPlanEvidence.file = file;
    return vehAssmntPlanEvidence;
  }
  getEvidenceBasePath(): string {
    const modelYear = this.auditVehicleInfo.modelYear;
    const modelCode = this.auditVehicleInfo.modelCode;
    const vdc = this.auditVehicleInfo.vdc;
    const vin = this.auditVehicleInfo.vin;
    const vehAssmntEventId = this.auditVehicleInfo.vehAssmntEventId;
    return `${modelYear}/${modelCode}/${vdc}/${vin}/${vehAssmntEventId}`;
  }
  generateBaseEvidencePath(assessPlan: VehAssmntEventPlan, file: File, index: number) {
    const evidenceBasePath = this.getEvidenceBasePath();
    const scopeType = assessPlan.vehAssmntScopeTypeCd;
    const vehAssmntEventPlanId = assessPlan.vehAssmntEventPlanId;
    const fileExtension = file.name.split(".")[1];
    return `${evidenceBasePath}/${scopeType}/${index + 1}.${fileExtension}`;
  }
  generateDataEvidencePath(assessPlan: VehAssmntEventPlan, assessData: VehAssmntData, file: File, index: number) {
    const evidenceBasePath = this.getEvidenceBasePath();
    const scopeType = assessPlan.vehAssmntScopeTypeCd;
    const scopeItem = assessData.vehAssmntScopeItemCd;
    const scopeItemId = assessData.vehAssmntDataId;
    const fileExtension = file.name.split(".")[1];
    return `${evidenceBasePath}/${scopeType}/${scopeItem}/${scopeItemId}/${index + 1}.${fileExtension}`;
  }
  getBasePath(auditData: AuditData): string {
    const ModelYear = auditData.vehDocMaster.modelYear;
    const ModelCode = auditData.vehDocMaster.modelCode;
    const VDC = auditData.vehDocMaster.facilityCd;
    const Vin = auditData.vehDocMaster.vin;
    const VehAssmntEventId = auditData.vehAssmntEventId;
    return `${ModelYear}/${ModelCode}/${VDC}/${Vin}/${VehAssmntEventId}`
  }
  setVerificationInputValues(dataList: DataVerificationModel[], assessData: VehAssmntData, assessPlan: VehAssmntEventPlan): VehAssmntData {
    dataList.forEach((element: DataVerificationModel) => {
      if (assessData.vehAssmntDataId === element.assessId) {
        assessData.vehAssmntActualData = element.data;
        assessData.vehAssmntValidationStatusCd = transformValidationStatusCdForAPI(element.status);
        assessData.vehAssmntDataComment = element.comment;
        assessData.vehAssmntDataEvidence = [];
        element.evidences.forEach((evidence: File, index: number) => {
          assessData.vehAssmntDataEvidence.push(this.setVehAssmntDataEvidence(assessPlan, assessData, evidence, index));
        });
      }
    });
    return assessData;
  }
  determineMajorSourcesOfForiegnPartsContentLine2Visibility() {
    if (this.MajorSourcesOfForiegnPartsContentCountry2?.value != null && this.MajorSourcesOfForiegnPartsContentPercent2?.value != null) {
      this.displayMajorSourcesLine2 = true;
    } else {
      this.displayMajorSourcesLine2 = false;
    }
  }
  displayMajorSourcesOfForeignPartsContentLine2() {
    this.displayMajorSourcesLine2 = true;
  }
  hideMajorSourcesOfForeignPartsContentLine2() {
    this.displayMajorSourcesLine2 = false;
  }
  onAttachAdditionalFiles(event: any) {
    this.currentFiles = [];
    for (let i = 0; i < event.files.length; i++) {
      this.currentFiles.push(event.files[i]);
    }
    this.currentFiles.forEach(element => {
      let status = false;
      this.additionalLabelEvidence.forEach(item => {
        if (element.name === item.file.name) {
          status = true;
        }
      });
      if (!status) {
        this.additionalLabelEvidence.push({ file: element, comment: "" });
        this.additionalLabelcomment = "";
        this.displayAdditionalFilesCommentPopup = true;
      }
    });
  }
  viewAdditionalLabelComment(item: { file: File, comment: string }) {
    this.currentFiles = [item.file];
    this.additionalLabelcomment = item.comment;
    this.displayAdditionalFilesCommentPopup = true;
  }
  onSaveAdditionalLabelComment() {
    this.currentFiles.forEach(element => {
      this.additionalLabelEvidence.forEach(item => {
        if (element.name === item.file.name) {
          item.comment = this.additionalLabelcomment;
        }
      });
    });
    this.displayAdditionalFilesCommentPopup = false;
  }
  getSizeInMegaBytes(file: File) {
    return file ? file.size / 1000000 : 0;
  }
  onRemoveAdditionalLabelComment() {
    this.currentFiles.forEach(element => {
      this.additionalLabelEvidence.forEach(item => {
        if (element.name === item.file.name) {
          item.comment = "";
        }
      });
    });
    this.displayAdditionalFilesCommentPopup = false;
  }
  removeAdditionalLabelEvid(fileName: any): any {
    this.additionalLabelEvidence.forEach((element, index) => {
      if (fileName === element.file.name) {
        this.additionalLabelEvidence.splice(index, 1);
      }
    });
  }
  mlversionData(event) {
    if (!isNullOrUndefined(event.target)) {
      const mlVersionValue = (+event.target.value).toFixed(2);
      this.MlVersion?.setValue(mlVersionValue);
    }
  }
  gvwrData(event) {
    if (!isNullOrUndefined(event.target)) {
      const gvwrValue = (+event.target.value).toFixed(2);
      this.GVWR?.setValue(gvwrValue);
    }
  }
}
