import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BrandApiModel } from 'src/app/core/model/api/brand-api.model';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { Distributor, DropDownModel } from 'src/app/models/dropdown.model';
import { StaticData } from '../../static-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vma-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  selectedBrand: any;
  brands: Array<DropDownModel>;
  @Output() brandEvent = new EventEmitter<any>();
  brandSubscription = new Subscription();

  constructor(
    public sharedService: SharedService,
    public pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
    const distributors = StaticData.getDistributorList();
    this.listBrands(distributors[0]);
    this.handleClearButton();
  }

  listBrands(distributor: Distributor) {
    const parames = {};
    if (!isNullOrUndefined(distributor)) {
      const distributorId: number = distributor.distributorId;
      this.sharedService.clearPreviousMessages();
      this.pcpsDistributorService.listBrands(distributorId, parames).subscribe((res) => {
        this.brands = new Array<DropDownModel>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const brandsResp: Array<BrandApiModel> = res.data[0].brands;
          brandsResp.forEach(element => {
            const data: DropDownModel = { id: element.brandId, name: element.brandNm };
            this.brands.push(data);
          });
          this.setSelectedBrandValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });      
    }
  }

  onBrandChange(event: any) {
    this.selectedBrand = event.value;
    this.brandEvent.emit(this.selectedBrand);
  }

  handleClearButton() {
    this.brandSubscription = this.sharedService.myObservable$.subscribe(res => {
      if (res) {
        this.selectedBrand = null;
        this.brandEvent.emit(this.selectedBrand);
      }
    })
  }

  setSelectedBrandValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) 
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.brandId)) {
      this.selectedBrand = this.sharedService.vehicleSearchData.brandId;
      this.brandEvent.emit(this.selectedBrand);
    }
  }

  ngOnDestroy(): void {
    this.brandSubscription.unsubscribe();
  }

}
