import { VehAssmntEventWorkflow, VehDocMaster } from "./audit-data.model";
import { isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';


export class AuditVehicleData {
    vehAssmntEventId: number;
    vehDocMaster: VehDocMaster;
    vehAssmntEventWorkflow: VehAssmntEventWorkflow;
}

export class FinalAssemblyPoint {
    name: string;
}

export class CountryOfOrigin {
    name: string;
    code: string;
}

export interface IVehicleAccessories {
    componentCd: string;
    componentTypeCd: string;
}

export class AuditVehicleListDetails {
    vehdocId: number;
    unitId: number;
    vin: string;
    modelYear: string;
    modelCode: string;
    nvsCatgCd: string;
    nvsStatCd: string;
    colorCd: string;
    exteriorColorCd: string;
    interiorColorCd: string;
    trimCd: string;
    vdc: string
    serialNo: string;
    arrivalDate: string;
    workflowStatus: string;
    accessorList: Array<IVehicleAccessories> = new Array<IVehicleAccessories>();    
    accessories: string;
    vehAssmntEventId: number;
    actionDateTime: string;
    actorComments: string;
    actorDeviceId: string;
    actorId: number;
    actorName: string;
    workflowId: number;
    constructor(data: any) {
        this.vehdocId = data.vehdocId;
        this.unitId = data.unitId;
        this.vin = data.vin;
        this.modelYear = data.modelYear;
        this.modelCode = data.modelCode;
        this.nvsCatgCd = data.nvsCatgCd;
        this.nvsStatCd = data.nvsStatCd;
        this.exteriorColorCd = data.exteriorColorCd;
        this.interiorColorCd = data.interiorColorCd;
        this.colorCd = this.setVehicleColor(data);
        this.trimCd = data.trimCd;
        this.vdc = data.fcltyCd;
        this.serialNo = data.serialNo;
        this.arrivalDate = data.deliveryDate;
        this.workflowStatus = data.workflowStatus;
        this.accessorList = data.accessorList;
        this.accessories = this.setAccessories(data.accessorList);
        this.vehAssmntEventId = data.vehAssmntEventId;
        this.actionDateTime = data.actionDateTime;
        this.actorComments = data.actorComments;
        this.actorDeviceId = data.actorDeviceId;
        this.actorId = data.actorId;
        this.actorName = data.actorName;
        this.workflowId = data.workflowId;
    }

    setAccessories(data): string {
        return data.map(u => u.componentCd).join(' | ');
    }

    setVehicleColor(data): string {
        return `${data.exteriorColorCd}/${data.interiorColorCd}${data.trimCd}`;
    }
}

export class AuditVehicleList {
    limit: number;
    offset: number;
    total: string;
    vehicleList: Array<AuditVehicleListDetails> = new Array<AuditVehicleListDetails>();
}

export class FacultyCodeList {    
    facultyCodeList: Array<VehicleFacultyCode> = new Array<VehicleFacultyCode>();
}

export class VehicleFacultyCode {
    code: string;
    name: string;
    constructor(data: any) {
        if (!isNullOrUndefined(data)) {
            this.code = data.vdcCode;
            this.name = data.vdcName;
        }
    }
}

export class AccessoryList {    
    accessory: Array<VehicleAccessory> = new Array<VehicleAccessory>();
}

export class VehicleAccessory {
    code: number;
    name: string;
    // typeCd: string;
    constructor(data: any) {
        if (!isNullOrUndefined(data)) {
            this.code = data.vehCompCd;
            this.name = data.vehCompTypeCd;            
        }
    }
}

export class AuditWaiverList {
    modelYear: number;
    modelCd: number;
    productGroupId: number;
    auditWaiverId: number;
    auditWaiverExcp: string;
    auditWaiverExcpTypeCode: string;
    activeInd: string;
    createdts: string;
    userName: string;
}

export class AuditHistoryWaiverData {
        modelYear: string;
        modelCode: string;
        // productGroupId: string;
        // auditWaiverId: number;
        // auditWaiverExcp : string;
        // activeInd: string;
        // createdts: string;
        // auditHistory: Array<IWaiverHistoryList>;
    }
    
export interface IWaiverHistoryList{
        auditId: string;
        historyId: string;
        actorNm: string;
        updatedTs: string;
        comment: string;
}

// export class AuditWaiverData {
//     modelYear: string;
//     modelCode: string;
//     // productGroupId: string;
//     // auditWaiverId: number;
//     // auditWaiverExcp : string;
//     // activeInd: string;
//     // createdts: string;
//     // auditHistory: Array<IWaiverHistoryList>;

// }



