<div class="cmn-div-padding">
    <h3>MY AUDIT INBOX</h3>

    <p-tabView styleClass="cmn-tabmenu-style" [scrollable]="true" [(activeIndex)]="activeIndex">
        <p-tabPanel header="READY FOR AUDIT">
            <p-table #table1 [value]="readyForAuditDataList" styleClass="p-datatable-sm" selectionMode="single"
                [(selection)]="selectedReadyForAuditRow" [paginator]="true" [rows]="20" [showCurrentPageReport]="true"
                responsiveLayout="scroll"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[20,40,60]"
                [globalFilterFields]="['vehDocMaster.vin' ,'vehDocMaster.accessories',
                'vehDocMaster.modelYear','vehDocMaster.modelCode','vehDocMaster.facilityCd','vehDocMaster.serialNum', 'vehAssmntEventWorkflow.actorNm' ]">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <button pButton type="button" [disabled]="readyForAuditDataList.length == 0" label="Scan"
                            class="p-button-secondary cmn-button-style" (click)="onBarCodeScan()"></button>

                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table1)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 100px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Year</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Code</th>
                        <th class="cmn-table-header-style" style="width: 60px" scope="col">Vdc</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-audit let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="audit" [pSelectableRowIndex]="rowIndex">
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.vin}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.accessories}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelYear}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelCode}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.facilityCd}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.serialNum}}</td>
                        <td class="cmn-table-data-style">
                            {{audit.vehDocMaster.exteriorColorCd}}/{{audit.vehDocMaster.interiorColorCd}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="readyForAuditDataList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="align-right page-action-panel-style">
                <p-button label="INPUT AUDIT" [disabled]="!selectedReadyForAuditRow" styleClass="cmn-button-style"
                    (click)="onInputAudit()">
                </p-button>
            </div>
        </p-tabPanel>
        <p-tabPanel header="READY TO SUBMIT">
            <p-table #table2 [value]="readyToSubmitDataList" styleClass="p-datatable-sm" selectionMode="multiple"
                [(selection)]="selectedReadyToSubmitRow" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                responsiveLayout="scroll"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10,20,30]"
                [globalFilterFields]="['vehDocMaster.vin' ,'vehDocMaster.accessories',
                'vehDocMaster.modelYear','vehDocMaster.modelCode','vehDocMaster.facilityCd','vehDocMaster.serialNum', 'vehAssmntEventWorkflow.actorNm' ]">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table2)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem" scope="col">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="cmn-table-header-style" style="width: 100px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Year</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Code</th>
                        <th class="cmn-table-header-style" style="width: 60px" scope="col">Vdc</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-audit let-rowIndex="rowIndex">
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="audit"></p-tableCheckbox>
                        </td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.vin}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.accessories}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelYear}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelCode}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.facilityCd}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.serialNum}}</td>
                        <td class="cmn-table-data-style">
                            {{audit.vehDocMaster.exteriorColorCd}}/{{audit.vehDocMaster.interiorColorCd}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td></td>
                        <td [attr.colspan]="readyToSubmitDataList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>

            </p-table>
            <div class="align-right page-action-panel-style">
                <p-button label="SUBMIT AUDIT" *ngIf="authService.isUserLoggedIn()"
                    [disabled]="selectedReadyToSubmitRow.length == 0" styleClass="cmn-button-style"
                    (click)="onSubmitAudit()">
                </p-button>
            </div>
        </p-tabPanel>
        <p-tabPanel header="PARTIAL AUDITS">
            <p-table #table3 [value]="partialAuditDataList" styleClass="p-datatable-sm" selectionMode="single"
                [(selection)]="selectedPartialAuditRow" [paginator]="true" [rows]="20" [showCurrentPageReport]="true"
                responsiveLayout="scroll"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[20,40,60]"
                [globalFilterFields]="['vehDocMaster.vin' ,'vehDocMaster.accessories',
                'vehDocMaster.modelYear','vehDocMaster.modelCode','vehDocMaster.facilityCd','vehDocMaster.serialNum', 'vehAssmntEventWorkflow.actorNm' ]">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table3)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 100px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Year</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Code</th>
                        <th class="cmn-table-header-style" style="width: 60px" scope="col">Vdc</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-audit let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="audit" [pSelectableRowIndex]="rowIndex">
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.vin}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.accessories}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelYear}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.modelCode}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.facilityCd}}</td>
                        <td class="cmn-table-data-style">{{audit.vehDocMaster.serialNum}}</td>
                        <td class="cmn-table-data-style">
                            {{audit.vehDocMaster.exteriorColorCd}}/{{audit.vehDocMaster.interiorColorCd}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="partialAuditDataList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="align-right page-action-panel-style">
                <p-button label="EDIT AUDIT" [disabled]="!selectedPartialAuditRow" styleClass="cmn-button-style"
                    (click)="onEditAudit()">
                </p-button>
            </div>
        </p-tabPanel>
    </p-tabView>

    <p-dialog [modal]="true" [closable]="false" [(visible)]="displaySubmitPopup">
        <ng-template pTemplate="header">
            <h5>Submit Status</h5>
        </ng-template>
        <ng-template pTemplate="content">
            <router-outlet></router-outlet>
        </ng-template>
    </p-dialog>

    <barcode-scanner-livestream-overlay [type]="allowedBarCodeFormats" (valueChanges)="onBarCodeValueChanges($event)">
    </barcode-scanner-livestream-overlay>


    <p-dialog [modal]="true" [style]="{width: '90%'}" [closable]="true" [(visible)]="displaySubmitPopup"
        [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header">
            <h5 class="cmn-label-font-style">AUDIT SUBMIT INFORMATION</h5>
        </ng-template>
        <ng-template pTemplate="content">
            <vma-submit-audit [auditSubmitList]="auditSubmitList"></vma-submit-audit>
        </ng-template>
    </p-dialog>
</div>