<div class="cmn-div-padding">
    <h3>INPUT AUDIT FORM</h3>

    <vma-page-title-info [auditVehicleInfo]="auditVehicleInfo"></vma-page-title-info>

    <div class="form-background-style">
        <form [formGroup]="inputAuditForm">
            <div formGroupName="monroneyLabel">
                <p-panel [header]="monroneyLabelDesc" [toggleable]="!monroneyLabelFlag" [collapsed]="monroneyLabelFlag">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Version</label>
                            <input class="cmn-input-font-style" type="text" pInputText pKeyFilter="num"
                                placeholder="Ex : 1.00" formControlName="mlVersion" (blur)="mlversionData($event)">
                            <div *ngIf="MlVersion?.invalid && (MlVersion?.dirty || MlVersion?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="MlVersion?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small *ngIf="MlVersion?.errors?.['InvalidMlVersion']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Final Assembly Point</label>
                            <p-dropdown panelStyleClass="cmn-input-font-style" [options]="finalAssemblyPointList"
                                formControlName="finalAssemblyPoint" placeholder="Select" [showClear]="true">
                            </p-dropdown>

                            <div *ngIf="MlFinalAssemblyPoint?.invalid && (MlFinalAssemblyPoint?.dirty || MlFinalAssemblyPoint?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="MlFinalAssemblyPoint?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small *ngIf="MlFinalAssemblyPoint?.errors?.['InvalidMlFinalAssemblyPoint']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Standard Features</label>
                            <p-table [value]="standardFeaturesList" styleClass="p-datatable-sm"
                                responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="cmn-table-header-style" scope="col">Data</th>
                                        <th class="cmn-table-header-style" scope="col">Status</th>
                                        <th class="cmn-table-header-style" scope="col">Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowdata let-i="rowIndex">
                                    <tr>
                                        <td class="cmn-table-data-style custm-width-60" style="white-space: pre">
                                            <strong *ngIf="rowdata.data.indexOf('#',0) == 0 "> {{
                                                rowdata.data.substring(1) }} </strong>
                                            <span *ngIf="rowdata.data.indexOf('#',0) != 0 "> {{ rowdata.data }} </span>
                                        </td>
                                        <td class="cmn-table-data-style custm-width-15">
                                            <span
                                                [class]="'cmn-validation-status-badge cmn-validation-status-' + rowdata.status">
                                                {{rowdata.status}}</span>
                                        </td>
                                        <td class="cmn-table-data-style custm-width-35">
                                            <button pButton pRipple icon="pi pi-check"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-success p-mr-2"
                                                (click)="onStandardFeaturesMatch(rowdata)"></button>

                                            <button pButton pRipple icon="pi pi-times"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-danger p-mr-2"
                                                (click)="onStandardFeaturesMismatch(rowdata)"></button>

                                            <button *ngIf="rowdata.comment != null" pButton pRipple icon="pi pi-comment"
                                                class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewStandardFeaturesComment(rowdata)"></button>

                                            <button *ngIf="rowdata.evidences.length != 0" pButton pRipple
                                                icon="pi pi-image" class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewStandardFeaturesAttachments(rowdata)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Optional Features</label>
                            <p-table [value]="optionalFeaturesList" styleClass="p-datatable-sm"
                                responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="cmn-table-header-style" scope="col">Desc</th>
                                        <th class="cmn-table-header-style" scope="col">Status</th>
                                        <th class="cmn-table-header-style" scope="col">Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowdata let-i="rowIndex">
                                    <tr>
                                        <td class="cmn-table-data-style custm-width-60" style="white-space: pre">
                                            {{rowdata.data}}
                                        </td>
                                        <td class="cmn-table-data-style custm-width-15">
                                            <span
                                                [class]="'cmn-validation-status-badge cmn-validation-status-' + rowdata.status">
                                                {{rowdata.status}}</span>
                                        <td class="cmn-table-data-style custm-width-35">
                                            <button pButton pRipple icon="pi pi-check"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-success p-mr-2"
                                                (click)="onOptionalFeaturesMatch(rowdata)"></button>

                                            <button pButton pRipple icon="pi pi-times"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-danger p-mr-2"
                                                (click)="onOptionalFeaturesMismatch(rowdata)"></button>

                                            <button *ngIf="rowdata.comment != null" pButton pRipple icon="pi pi-comment"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-secondary p-mr-2"
                                                (click)="viewOptionalFeaturesComment(rowdata)"></button>

                                            <button *ngIf="rowdata.evidences.length != 0" pButton pRipple
                                                icon="pi pi-image" [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-secondary p-mr-2"
                                                (click)="viewOptionalFeaturesAttachments(rowdata)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Color</label>
                            <p-table [value]="colorList" styleClass="p-datatable-sm" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="cmn-table-header-style" scope="col">Desc</th>
                                        <th class="cmn-table-header-style" scope="col">Status</th>
                                        <th class="cmn-table-header-style" scope="col">Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowdata let-i="rowIndex">
                                    <tr>
                                        <td class="cmn-table-data-style custm-width-60"> {{ rowdata.data }} </td>
                                        <td class="cmn-table-data-style custm-width-15">
                                            <span
                                                [class]="'cmn-validation-status-badge cmn-validation-status-' + rowdata.status">
                                                {{rowdata.status}}</span>
                                        <td class="cmn-table-data-style custm-width-35">
                                            <button pButton pRipple icon="pi pi-check"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-success p-mr-2"
                                                (click)="onColorMatch(rowdata)"></button>

                                            <button pButton pRipple icon="pi pi-times"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-danger p-mr-2"
                                                (click)="onColorMismatch(rowdata)"></button>

                                            <button *ngIf="rowdata.comment != null" pButton pRipple icon="pi pi-comment"
                                                class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewColorComment(rowdata)"></button>

                                            <button *ngIf="rowdata.evidences.length != 0" pButton pRipple
                                                icon="pi pi-image" class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewColorAttachments(rowdata)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Upload Monroney Label</label>
                            <p-fileUpload chooseIcon="pi pi-plus" [files]="monroneyBaseEvidence"
                                styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                                (onSelect)="onAttachBaseFiles($event , 'MONRONEY_LABEL')"
                                (onRemove)="onRemoveBaseFiles('MONRONEY_LABEL')">
                            </p-fileUpload>
                        </div>
                    </div>
                </p-panel>
            </div>
            <br>
            <div formGroupName="aalaLabel">
                <p-panel [header]="aalaLabelDesc" [toggleable]="!aalaLabelFlag" [collapsed]="aalaLabelFlag">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">US/CANADA Parts
                                Content</label>
                            <div class="p-inputgroup cmn-input-font-style">
                                <input class="cmn-input-font-style" type="text" pInputText pKeyFilter="num"
                                    placeholder="Ex : 15" formControlName="usCanadaPartsContent">
                                <span class="p-inputgroup-addon">%</span>
                            </div>

                            <div *ngIf="UsCanadaPartsContent?.invalid && (UsCanadaPartsContent?.dirty || UsCanadaPartsContent?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="UsCanadaPartsContent?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small *ngIf="UsCanadaPartsContent?.errors?.['InvalidUsCanadaPartsContent']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Final Assembly Point</label>
                            <p-dropdown panelStyleClass="cmn-input-font-style" [options]="finalAssemblyPointList"
                                formControlName="finalAssemblyPoint" placeholder="Select" [showClear]="true">
                            </p-dropdown>

                            <div *ngIf="AalaFinalAssemblyPoint?.invalid && (AalaFinalAssemblyPoint?.dirty || AalaFinalAssemblyPoint?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="AalaFinalAssemblyPoint?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small *ngIf="AalaFinalAssemblyPoint?.errors?.['InvalidAALAFinalAssemblyPoint']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Major Sources of Foriegn Parts
                                Content</label>
                            <div class="p-field p-grid" formGroupName="majorSourcesOfForeignPartsContentLine_1">
                                <label class="cmn-label-font-style p-col-fixed" style="width:30px">#1</label>
                                <div class="p-col">
                                    <p-dropdown [options]="countryOfOriginList" panelStyleClass="cmn-input-font-style"
                                        formControlName="country" placeholder="Select Country" [showClear]="true">
                                    </p-dropdown>

                                    <div *ngIf="MajorSourcesOfForiegnPartsContentCountry1?.invalid && (MajorSourcesOfForiegnPartsContentCountry1?.dirty || MajorSourcesOfForiegnPartsContentCountry1?.touched)"
                                        class="cmn-validation-error">
                                        <small *ngIf="MajorSourcesOfForiegnPartsContentCountry1?.errors?.['required']">
                                            This field is mandatory
                                        </small>
                                    </div>
                                    <div *ngIf="MajorSourcesOfForiegnPartsContentLine1?.invalid && (MajorSourcesOfForiegnPartsContentLine1?.dirty || MajorSourcesOfForiegnPartsContentLine1?.touched)"
                                        class="cmn-validation-error">
                                        <small
                                            *ngIf="MajorSourcesOfForiegnPartsContentLine1?.errors?.['InvalidMajorSourcesLine1']
                                            && !MajorSourcesOfForiegnPartsContentCountry1?.errors?.['required'] && !MajorSourcesOfForiegnPartsContentPercent1?.errors?.['required']">
                                            #1 Country & its Percent value is not matching.
                                        </small>
                                    </div>
                                </div>
                                <div class="p-col">
                                    <div class="p-inputgroup cmn-input-font-style">
                                        <input type="text" class="cmn-input-font-style" formControlName="percent"
                                            pInputText pKeyFilter="num" placeholder="Ex : 60.0">
                                        <span class="p-inputgroup-addon">%</span>
                                    </div>

                                    <div *ngIf="MajorSourcesOfForiegnPartsContentPercent1?.invalid && (MajorSourcesOfForiegnPartsContentPercent1?.dirty || MajorSourcesOfForiegnPartsContentPercent1?.touched)"
                                        class="cmn-validation-error">
                                        <small *ngIf="MajorSourcesOfForiegnPartsContentPercent1?.errors?.['required']">
                                            This field is mandatory
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="p-field p-grid" formGroupName="majorSourcesOfForeignPartsContentLine_2"
                                *ngIf="displayMajorSourcesLine2">
                                <label class="cmn-label-font-style p-col-fixed" style="width:30px">#2</label>
                                <div class="p-col">
                                    <p-dropdown [options]="countryOfOriginList" panelStyleClass="cmn-input-font-style"
                                        formControlName="country" placeholder="Select Country" [showClear]="true">
                                    </p-dropdown>
                                    <div *ngIf="MajorSourcesOfForiegnPartsContentCountry2?.invalid && (MajorSourcesOfForiegnPartsContentCountry2?.dirty || MajorSourcesOfForiegnPartsContentCountry2?.touched)"
                                        class="cmn-validation-error">
                                        <small *ngIf="MajorSourcesOfForiegnPartsContentCountry2?.errors?.['required']">
                                            This field is mandatory
                                        </small>
                                    </div>
                                    <div *ngIf="MajorSourcesOfForiegnPartsContentLine2?.invalid && (MajorSourcesOfForiegnPartsContentLine2?.dirty || MajorSourcesOfForiegnPartsContentLine2?.touched)"
                                        class="cmn-validation-error">
                                        <small
                                            *ngIf="MajorSourcesOfForiegnPartsContentLine2?.errors?.['InvalidMajorSourcesLine2']
                                            && !MajorSourcesOfForiegnPartsContentCountry2?.errors?.['required'] && !MajorSourcesOfForiegnPartsContentPercent2?.errors?.['required']">
                                            #2 Country & its Percent value is not matching.
                                        </small>
                                    </div>
                                </div>
                                <div class="p-col">
                                    <div class="p-inputgroup cmn-input-font-style">
                                        <input type="text" class="cmn-input-font-style" formControlName="percent"
                                            pInputText pKeyFilter="num" placeholder="Ex : 60.0">
                                        <span class="p-inputgroup-addon">%</span>
                                    </div>
                                    <div *ngIf="MajorSourcesOfForiegnPartsContentPercent2?.invalid && (MajorSourcesOfForiegnPartsContentPercent2?.dirty || MajorSourcesOfForiegnPartsContentPercent2?.touched)"
                                        class="cmn-validation-error">
                                        <small *ngIf="MajorSourcesOfForiegnPartsContentPercent2?.errors?.['required']">
                                            This field is mandatory
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="!displayMajorSourcesLine2" pButton pRipple type="button" icon="pi pi-plus"
                                class="p-button-rounded p-button-text p-button-plain"
                                (click)="displayMajorSourcesOfForeignPartsContentLine2()"></button>
                            <button *ngIf="displayMajorSourcesLine2" pButton pRipple type="button" icon="pi pi-trash"
                                class="p-button-rounded p-button-text p-button-plain"
                                (click)="hideMajorSourcesOfForeignPartsContentLine2()"></button>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Country Of Origin - Engine
                                Parts</label>
                            <p-autoComplete inputStyleClass="cmn-input-font-style"
                                panelStyleClass="cmn-input-font-style" formControlName="countryOfOriginEngineParts"
                                [showEmptyMessage]="true" [suggestions]="filteredCountries"
                                placeholder="Type in to search or Select" (completeMethod)="filterCountry($event)"
                                [dropdown]="true">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>

                            <div *ngIf="CountryOfOriginEngineParts?.invalid && (CountryOfOriginEngineParts?.dirty || CountryOfOriginEngineParts?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="CountryOfOriginEngineParts?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small
                                    *ngIf="CountryOfOriginEngineParts?.errors?.['InvalidCountryOfOriginEngineParts']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-label-font-style ">Transmission Serial Number :

                                <span *ngIf="TransmissionSerialNumber?.value != 'NA'"
                                    class="transmission-serial-number-available-style">
                                    {{TransmissionSerialNumber?.value}}</span>
                                <span *ngIf="TransmissionSerialNumber?.value == 'NA'"
                                    class="transmission-serial-number-not-available-style">
                                    {{TransmissionSerialNumber?.value}}</span></label>
                            <br>
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Country Of Origin -
                                Transmission
                                Parts</label>
                            <p-autoComplete inputStyleClass="cmn-input-font-style"
                                panelStyleClass="cmn-input-font-style"
                                formControlName="countryOfOriginTransmissionParts" [showEmptyMessage]="true"
                                [suggestions]="filteredCountries" placeholder="Type in to search or Select"
                                (completeMethod)="filterCountry($event)" [dropdown]="true">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                        <div>{{country}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>

                            <div *ngIf="CountryOfOriginTransmissionParts?.invalid && (CountryOfOriginTransmissionParts?.dirty || CountryOfOriginTransmissionParts?.touched)"
                                class="cmn-validation-error">
                                <small *ngIf="CountryOfOriginTransmissionParts?.errors?.['required']">
                                    This field is mandatory
                                </small>
                                <small
                                    *ngIf="CountryOfOriginTransmissionParts?.errors?.['InvalidCountryOfOriginTransmissionParts']">
                                    Entered value is not matching with source system.
                                </small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Upload AALA Label</label>
                            <p-fileUpload chooseIcon="pi pi-plus" [files]="aalaBaseEvidence"
                                styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                                (onSelect)="onAttachBaseFiles($event , 'AALA_LABEL')"
                                (onRemove)="onRemoveBaseFiles('AALA_LABEL')">
                            </p-fileUpload>
                        </div>
                    </div>
                </p-panel>
            </div>
            <br>
            <div>
                <p-panel [header]="ptag1Desc" [toggleable]="!ptag1Flag" [collapsed]="ptag1Flag">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Model FSA</label>
                            <p-table [value]="modelFsaList" styleClass="p-datatable-sm" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="cmn-table-header-style" scope="col">Acc Code</th>
                                        <th class="cmn-table-header-style" scope="col">Status</th>
                                        <th class="cmn-table-header-style" scope="col">Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowdata let-i="rowIndex">
                                    <tr>
                                        <td class="cmn-table-data-style custm-width-60"> {{ rowdata.data }} </td>
                                        <td class="cmn-table-data-style custm-width-15">
                                            <span
                                                [class]="'cmn-validation-status-badge cmn-validation-status-' + rowdata.status">
                                                {{rowdata.status}}</span>
                                        </td>
                                        <td class="cmn-table-data-style custm-width-35">
                                            <button pButton pRipple icon="pi pi-check"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-success p-mr-2"
                                                (click)="onModelFsaMatch(rowdata)"></button>

                                            <button pButton pRipple icon="pi pi-times"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                class="p-button-rounded p-button-danger p-mr-2"
                                                (click)="onModelFsaMismatch(rowdata)"></button>

                                            <button *ngIf="rowdata.comment != null" pButton pRipple icon="pi pi-comment"
                                                class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewModelFsaComment(rowdata)"></button>

                                            <button *ngIf="rowdata.evidences.length != 0" pButton pRipple
                                                icon="pi pi-image" class="p-button-rounded p-button-secondary p-mr-2"
                                                [style]="{'height' : '25px' , 'width' :'25px'}"
                                                (click)="viewModelFsaAttachments(rowdata)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td [attr.colspan]="modelFsaList.length" class="cmn-table-data-style">
                                            No records found
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label class="cmn-mandatory-field-style cmn-label-font-style">Upload PTAG Side 1
                                Label</label>
                            <p-fileUpload chooseIcon="pi pi-plus" [files]="ptag1BaseEvidence"
                                styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                                (onSelect)="onAttachBaseFiles($event , 'PTAG_1')"
                                (onRemove)="onRemoveBaseFiles('PTAG_1')">
                            </p-fileUpload>
                        </div>

                    </div>
                </p-panel>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col">
                    <p-panel [header]="ptag2Desc" [toggleable]="!ptag2Flag" [collapsed]="ptag2Flag">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 custom-height-60">
                            </div>
                            <div class="p-field p-col-12">
                                <label class="cmn-mandatory-field-style cmn-label-font-style">Upload PTAG Side 2
                                    Label</label>
                                <p-fileUpload chooseIcon="pi pi-plus" [files]="ptag2BaseEvidence"
                                    styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                    [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                                    capture="camera" (onSelect)="onAttachBaseFiles($event , 'PTAG_2')"
                                    (onRemove)="onRemoveBaseFiles('PTAG_2')">
                                </p-fileUpload>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="p-col" formGroupName="crLabel">
                    <p-panel [header]="crLabelDesc" [toggleable]="!crLabelFlag" [collapsed]="crLabelFlag">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label class="cmn-mandatory-field-style cmn-label-font-style">GVWR</label>
                                <input class="cmn-input-font-style" type="text" pInputText pKeyFilter="num"
                                    placeholder="Ex : 2500.00" formControlName="gvwr" (blur)="gvwrData($event)">

                                <div *ngIf="GVWR?.invalid && (GVWR?.dirty || GVWR?.touched)"
                                    class="cmn-validation-error">
                                    <small *ngIf="GVWR?.errors?.['required']">
                                        This field is mandatory
                                    </small>
                                    <small *ngIf="GVWR?.errors?.['InvalidGvwr']">
                                        Entered value is not matching with source system.
                                    </small>
                                </div>
                            </div>
                            <div class="p-field p-col-12">
                                <label class="cmn-mandatory-field-style cmn-label-font-style">Upload CR Label</label>
                                <p-fileUpload chooseIcon="pi pi-plus" [files]="crBaseEvidence"
                                    styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                    [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                                    capture="camera" (onSelect)="onAttachBaseFiles($event , 'CR_LABEL')"
                                    (onRemove)="onRemoveBaseFiles('CR_LABEL')">
                                </p-fileUpload>
                            </div>
                        </div>
                    </p-panel>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col">
                    <p-panel [header]="awrLabelDesc" [toggleable]="!awrLabelFlag" [collapsed]="awrLabelFlag">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label class="cmn-mandatory-field-style cmn-label-font-style">Upload AWR Label</label>
                                <p-fileUpload chooseIcon="pi pi-plus" [files]="awrBaseEvidence"
                                    styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                    [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                                    capture="camera" (onSelect)="onAttachBaseFiles($event , 'AWR_LABEL')"
                                    (onRemove)="onRemoveBaseFiles('AWR_LABEL')">
                                </p-fileUpload>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="p-col">
                    <p-panel [header]="emissonLabelDesc" [toggleable]="!emissionLabelFlag"
                        [collapsed]="emissionLabelFlag">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label class="cmn-label-font-style">Upload Emissions
                                    Label</label>
                                <p-fileUpload chooseIcon="pi pi-plus" [files]="emissionsBaseEvidence"
                                    styleClass="cmn-input-font-style" chooseLabel="Add Attachment" [multiple]="false"
                                    [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                                    capture="camera" (onSelect)="onAttachBaseFiles($event , 'EMISSION_LABEL')"
                                    (onRemove)="onRemoveBaseFiles('EMISSION_LABEL')">
                                </p-fileUpload>
                            </div>
                        </div>
                    </p-panel>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col">
                    <p-panel header="Additional Labels" [toggleable]="true">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <p-fileUpload chooseIcon="pi pi-plus" styleClass="cmn-input-font-style"
                                    chooseLabel="Add Attachment" [multiple]="true" [showUploadButton]="false"
                                    [showCancelButton]="false" accept="image/*" capture="camera"
                                    (onSelect)="onAttachAdditionalFiles($event)">
                                    <ng-template pTemplate="file">
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div *ngFor="let item of additionalLabelEvidence" class="p-fileupload-row">
                                            <div>{{item.file?.name}}</div>
                                            <div> {{getSizeInMegaBytes(item.file) | number:'1.2-2'}} MB</div>
                                            <div>
                                                <button *ngIf="item.comment != ''" pButton pRipple icon="pi pi-comment"
                                                    class="p-button-rounded p-button-secondary p-mr-2"
                                                    [style]="{'height' : '25px' , 'width' :'25px'}"
                                                    (click)="viewAdditionalLabelComment(item)"></button>
                                            </div>
                                            <div><button type="button" icon="pi pi-times" pButton
                                                    (click)="removeAdditionalLabelEvid(item.file?.name)"></button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-fileUpload>
                            </div>
                        </div>
                    </p-panel>
                </div>
            </div>
        </form>
    </div>
    <br>

    <div class="p-d-flex p-jc-between page-action-panel-style">
        <div>
            <button pButton type="button" class="p-button-secondary cmn-button-style" icon="pi pi-arrow-left"
                label="BACK" (click)="back()"></button>
        </div>
        <div>
            <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2"
                label="RESET" [disabled]="!this.inputAuditForm.dirty && !this.inputAuditForm.touched"
                (click)="reset()"></button>
            <button pButton type="button" icon="pi pi-save" class="cmn-button-style" label="SAVE"
                (click)="save()"></button>
        </div>
    </div>


    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayModelFsaMatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>

        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong> Match</strong>
                ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment"
                maxlength="250"> </textarea> <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>
        </ng-template>

        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onModelFsaConfirmNotMatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="onModelFsaConfirmMatch()" label="Yes">
            </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayModelFsaMismatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong>
                    Mismatch</strong> ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment" maxlength="250"
                required> </textarea>
            <div *ngIf="!lineItemModel.comment" class="cmn-validation-error">
                <small>
                    Reason for mismatch is mandatory.
                </small>
            </div>
            <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>

        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onModelFsaConfirmNotMismatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!lineItemModel.comment"
                (click)="onModelFsaConfirmMismatch()" label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayModelFsaCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" [disabled]="true" [autoResize]="true" pInputTextarea
                class="cmn-input-font-style" [(ngModel)]="lineItemModel.comment" maxlength="250"> </textarea> <br>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayModelFsaAttachmentsPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Attachment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <p-fileUpload chooseIcon="pi pi-plus" [disabled]="true" [files]="lineItemModel.evidences"
                chooseLabel="Add Attachment" styleClass="cmn-input-font-style" [multiple]="true"
                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                (onRemove)="onRemoveModelFsaAttachment($event)">
            </p-fileUpload>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayStandardFeaturesMatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>

        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong> Match</strong>
                ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment"
                maxlength="250"> </textarea> <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>
        </ng-template>

        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onStandardFeaturesConfirmNotMatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="onStandardFeaturesConfirmMatch()"
                label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayStandardFeaturesMismatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong>
                    Mismatch</strong> ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment" maxlength="250"
                required> </textarea>
            <div *ngIf="!lineItemModel.comment" class="cmn-validation-error">
                <small>
                    Reason for mismatch is mandatory.
                </small>
            </div>
            <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>

        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onStandardFeaturesConfirmNotMismatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!lineItemModel.comment"
                (click)="onStandardFeaturesConfirmMismatch()" label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayStandardFeatureCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" [disabled]="true" [autoResize]="true" pInputTextarea
                class="cmn-input-font-style" [(ngModel)]="lineItemModel.comment" maxlength="250"> </textarea> <br>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayStandardFeatureAttachmentsPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Attachment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <p-fileUpload chooseIcon="pi pi-plus" [disabled]="true" [files]="lineItemModel.evidences"
                chooseLabel="Add Attachment" styleClass="cmn-input-font-style" [multiple]="true"
                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                (onRemove)="onRemoveStandardFeaturesAttachment($event)">
            </p-fileUpload>
        </ng-template>
    </p-dialog>


    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayOptionalFeaturesMatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong> Match</strong>
                ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment"
                maxlength="250"> </textarea> <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onOptionalFeaturesConfirmNotMatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="onOptionalFeaturesConfirmMatch()"
                label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayOptionalFeaturesMismatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong>
                    Mismatch</strong> ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment" maxlength="250"
                required> </textarea>
            <div *ngIf="!lineItemModel.comment" class="cmn-validation-error">
                <small>
                    Reason for mismatch is mandatory.
                </small>
            </div>
            <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>

        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onOptionalFeaturesConfirmNotMismatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!lineItemModel.comment"
                (click)="onOptionalFeaturesConfirmMismatch()" label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayOptionalFeatureCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" class="cmn-input-font-style" [disabled]="true" [autoResize]="true"
                pInputTextarea [(ngModel)]="lineItemModel.comment" maxlength="250"> </textarea> <br>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayOptionalFeatureAttachmentsPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Attachment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <p-fileUpload chooseIcon="pi pi-plus" [disabled]="true" [files]="lineItemModel.evidences"
                chooseLabel="Add Attachment" styleClass="cmn-input-font-style" [multiple]="true"
                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                (onRemove)="onRemoveOptionalFeaturesAttachment($event)">
            </p-fileUpload>
        </ng-template>
    </p-dialog>



    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayColorMatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong> Match</strong>
                ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment"
                maxlength="250"> </textarea> <br>

            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style" (click)="onColorConfirmNotMatch()"
                label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="onColorConfirmMatch()" label="Yes">
            </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayColorMismatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark the below highlighted value as<strong>
                    Mismatch</strong> ?
            </h4>
            <h3 class="selected-value-highlight-style">{{lineItemModel.data}}</h3>

            <p class="cmn-input-font-style"> You can override color value below !</p>
            <input type="text" class="cmn-input-font-style" pInputText placeholder="Enter new color value here"
                [(ngModel)]="lineItemModel.data" />
            <br>
            <br>

            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="lineItemModel.comment" maxlength="250"
                required> </textarea>
            <div *ngIf="!lineItemModel.comment" class="cmn-validation-error">
                <small>
                    Reason for mismatch is mandatory.
                </small>
            </div>
            <br>

            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>

        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="onColorConfirmNotMismatch()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!lineItemModel.comment"
                (click)="onColorConfirmMismatch()" label="Yes">
            </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayColorCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" [disabled]="true" [autoResize]="true" pInputTextarea
                class="cmn-input-font-style" [(ngModel)]="lineItemModel.comment" maxlength="250"> </textarea> <br>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayColorAttachmentsPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Attachment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <p-fileUpload chooseIcon="pi pi-plus" [disabled]="true" [files]="lineItemModel.evidences"
                chooseLabel="Add Attachment" styleClass="cmn-input-font-style" [multiple]="true"
                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                (onRemove)="onRemoveColorAttachment($event)">
            </p-fileUpload>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayAdditionalFilesCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style" maxlength="250"
                [(ngModel)]="additionalLabelcomment"> </textarea> <br>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button styleClass="p-button-secondary cmn-button-style p-mr-2" (click)="onRemoveAdditionalLabelComment()"
                label="No Comment">
            </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!additionalLabelcomment"
                (click)="onSaveAdditionalLabelComment()" label="Save">
            </p-button>
        </ng-template>
    </p-dialog>
</div>