import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { VehicleSelectionComponent } from './vehicle-selection.component';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ModelViewModule } from 'src/app/modules/vehicle-search-criteria/model-view/model-view.module';
import { VdcViewModule } from 'src/app/modules/vehicle-search-criteria/vdc-view/vdc-view.module';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { MenuModule } from 'primeng/menu';


@NgModule({
  declarations: [
    VehicleSelectionComponent
  ],
  imports: [
    CommonModule,
    VdcViewModule,
    ModelViewModule,
    FormsModule,
    SelectButtonModule,
    DividerModule,
    ButtonModule,
    TabViewModule,
    TableModule,
    PanelModule,
    InputTextModule,
    PaginatorModule,
    MenuModule
  ],
  exports: [
    VehicleSelectionComponent
  ],
  providers: []
})
export class VehicleSelectionModule { }
