import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputAuditFormRoutingModule } from './audit-input-routing.module';
import { AuditInputComponent } from './audit-input.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PageTitleInfoModule } from 'src/app/modules/page-title-info/page-title-info.module';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [
    AuditInputComponent
  ],
  imports: [
    CommonModule,
    InputAuditFormRoutingModule,
    PageTitleInfoModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    InputTextModule,
    KeyFilterModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    FileUploadModule,
    AutoCompleteModule,
    InputTextareaModule,
    DialogModule,
    ConfirmDialogModule
  ]
})
export class AuditInputModule { }
