import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ApiInterceptor } from 'src/app/core/interceptor/api-interceptor';
import { AuditInboxModule } from '../audit-inbox/audit-inbox.module';
import { AuditInputModule } from '../audit-input/audit-input.module';
import { AuditReportModule } from '../audit-report/audit-report.module';
import { EvidenceDownloadModule } from '../evidence-download/evidence-download.module';
import { VehicleSelectionModule } from '../vehicle-selection/vehicle-selection.module';
import { HomeComponent } from './home.component';
import { CoreModule } from 'src/app/core/core.module';
import { AddVehicleModule } from '../add-vehicle/add-vehicle.module';
import { DrcApprovalModule } from '../drc-approval/drc-approval.module';
import { AdminModule } from '../admin/admin.module';


@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    CoreModule,
    AuditInputModule,
    AuditReportModule,
    AuditInboxModule,
    EvidenceDownloadModule,
    VehicleSelectionModule,
    AddVehicleModule,
    DrcApprovalModule,
    AdminModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }]
})
export class HomeModule { }
