import { AuditVehicleData, AuditVehicleList, AuditVehicleListDetails, VehicleFacultyCode, FacultyCodeList, AccessoryList, VehicleAccessory } from "../ui/audit-vehicle.data.model";
import * as VMA_Util from '../../utilities/vma-common.util';

export class ListVehicleApiModel extends AuditVehicleData { }

export class ListVehicleApiRespModel {
    vehicleList: Array<ListVehicleApiModel> = new Array<ListVehicleApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.auditVehicleList)) {
                options.data.auditVehicleList.forEach((element: any) => {
                    this.vehicleList.push({
                        vehAssmntEventId: element.vehAssmntEventId,
                        vehDocMaster: element.vehDocMaster,
                        vehAssmntEventWorkflow: element.vehAssmntEventWorkflow
                    })
                });
            }
        }
    }
}


export class AuditVehicleListApiModel extends AuditVehicleList { }

export class AuditVehicleListApiRespModel {
    auditVehicle: AuditVehicleListApiModel = new AuditVehicleListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.vehicleList)) {
                this.auditVehicle.limit = options.data.limit;
                this.auditVehicle.offset = options.data.offset;
                this.auditVehicle.total = options.data.total;                
                options.data.vehicleList.forEach((element: any) => {
                    this.auditVehicle.vehicleList.push(new AuditVehicleListDetails(element));
                });
            }
        }
    }
}

export class FacultyCodeListApiModel extends FacultyCodeList { }

export class FacultyCodeListApiRespModel {
    facultyCode: FacultyCodeListApiModel = new FacultyCodeListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.facultyCodeList)) {                              
                options.data.facultyCodeList.forEach((element: any) => {
                    this.facultyCode.facultyCodeList.push(new VehicleFacultyCode(element));
                });
            }
        }
    }
}


export class AccessoryListApiModel extends AccessoryList { }

export class AccessoryListApiRespModel {
    accessoryList: AccessoryListApiModel = new AccessoryListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.accyList)) {                              
                options.data.accyList.forEach((element: any) => {
                    this.accessoryList.accessory.push(new VehicleAccessory(element));
                });
            }
        }
    }
}
