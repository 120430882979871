import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { SharedService } from '../services/ui/shared.service';

@Component({
  selector: 'vma-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerMenuItems: MenuItem[];
  userMenuItems: MenuItem[];
  private readonly _destroying$ = new Subject<void>();
  isLoggedIn = false;
  userName: string;

  constructor(
    public authService: AuthService,
    public sharedService: SharedService,
    private readonly msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isLoggedIn = this.authService.isUserLoggedIn();
        if (this.isLoggedIn && this.authService.isAuthorized()) {
          this.sharedService.enableRoleBasedAccessControl();
          this.setUserName();
          this.createUserInfoItems();
        }
        this.createHeaderMenuItems();
      });
  }

  setUserName() {
    this.userName = this.authService.getAuthenticatedUserProfile().name;
  }
  createUserInfoItems() {
    this.userMenuItems = [
      {
        label: `${this.authService.getAuthorizedUserProfile().role.name}`,
        items: [
          {
            label: 'Logout', icon: 'pi pi-sign-out', command: () => {
              this.logout();
            }
          }
        ]
      }
    ]
  }
  createHeaderMenuItems() {
    this.headerMenuItems = [
      {
        label: 'Admin View',
        routerLink: "/audit/admin",
        //visible: this.isLoggedIn && this.sharedService.displayAdminMenu, 
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Vehicle Selection',
        routerLinkActiveOptions: { exact: true },
        items: [
          {
            label: 'Search',
            routerLink: "/audit/vehicle",
            routerLinkActiveOptions: { exact: true }
          },
          {
            label: 'Scan',
            routerLink: "/audit/scan",
            routerLinkActiveOptions: { exact: true }
          }
        ],
        visible: this.isLoggedIn && this.sharedService.displayVehicleSelectionMenu
      },
      {
        label: 'My Inbox',
        routerLink: "/audit/inbox",
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'DRC Approval',
        routerLink: "/audit/drc",
        visible: this.isLoggedIn && this.sharedService.displayDrcApprovalMenu,
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }
  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
