export class AuditData {
    vehAssmntEventId: number;
    vehAssmntRepPath: string;
    vehAssmntPlanTypeCd: string;
    cgVdcVehId: number;
    vehDocMaster: VehDocMaster;
    vehAssmntEventPlan: Array<VehAssmntEventPlan>;
    vehAssmntEventWorkflow: VehAssmntEventWorkflow;
    vehScopeItems ?: any;
}

export class VehAssmntEventWorkflow {
    vehAssmntEventWorkflowId: number;
    vehAssmntEventId: number;
    activeInd: string;
    actorComments: string;
    actorDeviceId: string;
    actionDateTime: string;
    workflowStatusCd: string;
    dataSyncStatusCd: string;
    actorId: string;
    actorNm: string;
}

export class VehAssmntDataEvidence {
    vehAssmntDataEvidenceId: any;
    vehAssmntDataEvidencePath: any;
    vehAssmntDataEvidenceUploadTs: any;
    vehAssmntDataEvidenceComment: any;
    vehAssmntDataId: number;
    file?: File;
}

export class VehAssmntData {
    vehAssmntDataId: any;
    vehAssmntScopeItemCd: any;
    vehAssmntEventPlanId: any;
    vehAssmntActualData: any;
    nvsDataReference: any;
    galcDataReference: any;
    vehAssmntValidationStatusCd: any;
    vehAssmntDataComment: any;
    vehAssmntDataEvidence: Array<VehAssmntDataEvidence>;
}

export class VehAssmntEventPlan {
    vehAssmntEventPlanId: number;
    vehAssmntScopeTypeCd: string;
    vehAssmntEventId: number;
    vehAssmntData: Array<VehAssmntData>;
    vehAssmntPlanEvidence: Array<VehAssmntPlanEvidence>;
}

export class VehAssmntPlanEvidence {
    vehAssmntPlanEvidenceId: any;
    vehAssmntPlanEvidencePath: any;
    vehAssmntPlanEvidenceUploadTs: any;
    vehAssmntPlanEvidenceComment: any;
    vehAssmntEventPlanId: number;
    file?: File
}

export class VehDocMaster {
    vehDocId: number;
    modelYear: string;
    modelCode: string;
    unitId: number;
    vin: string;
    serialNum: string;
    exteriorColorCd: string;
    interiorColorCd: string;
    trimCd: string;
    facilityCd: string;
    accessories: string;
}

